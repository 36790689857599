import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import LockIcon from "@material-ui/icons/Lock";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import api from "app/api";
import MainForm from "views/Dashboard/MainForm";
import { useKund } from "app/store";
import { useHamtstalle } from "app/store";

const useStyles = makeStyles(styles);

// eslint-disable-next-line no-unused-vars
const sss = [
  {
    ordernr: 94624,
    hsadress: "Alströmergatan 12",
    sekretessrader: [
      {
        ordernr: 94624,
        hsadress: "Alströmergatan 12",
        sekretessid: " 101",
        beskrivning: "Sekretesspapper  Kärl 140 L",
        utfdag_Sekretess: "2020-10-26T00:00:00",
        tomdkl_Sekretess: "09:57",
        utfdag_Destruering: "2020-10-26T00:00:00",
        tomdkl_Destruering: "11:42",
        destruerad: true,
        antal: 1.0,
      },
    ],
  },
  {
    ordernr: 94069,
    hsadress: "Alströmergatan 12",
    sekretessrader: [
      {
        ordernr: 94069,
        hsadress: "Alströmergatan 12",
        sekretessid: " 216",
        beskrivning: "Sekretesspapper  Kärl 140 L",
        utfdag_Sekretess: "2020-10-19T00:00:00",
        tomdkl_Sekretess: "11:05",
        utfdag_Destruering: "2020-10-19T00:00:00",
        tomdkl_Destruering: "15:46",
        destruerad: true,
        antal: 1.0,
      },
    ],
  },
];

// https://minasidor.smartrecycling.se/api/api/Profile/NastaFranHs/1945/1

// https://minasidor.smartrecycling.se/api/api/Profile/ForegaendeFranHs/1945/1

export default function SecretPage() {
  const classes = useStyles();
  const [checked, setChecked] = React.useState({});

  const [items, setItems] = React.useState([]);
  const [selectedKund] = useKund();
  const [selectedHamtstalle] = useHamtstalle();
  const hsnr = (selectedHamtstalle || { id: 0 }).id;

  const handleToggle = React.useCallback(
    (value, isChecked) => {
      checked[value] = isChecked;
      const newChecked = { ...checked };
      setChecked(newChecked);
    },
    [checked]
  );

  React.useEffect(() => {
    if (selectedKund) {
      // https://minasidor.smartrecycling.se/api/api/Tjanster/GetSekretess/139/0
      api.Tjanster.GetSekretess(selectedKund.kundnr, hsnr)
        .then((data) => {
          const items = [];
          data.forEach((item) => {
            item.sekretessrader.forEach((price) => {
              const {
                ordernr,
                hsadress,
                sekretessid,
                beskrivning,
                antal,
                utfdag_Sekretess,
                tomdkl_Sekretess,
                utfdag_Destruering,
                tomdkl_Destruering,
              } = price;
              items.push([
                "check",
                ordernr,
                hsadress,
                sekretessid,
                beskrivning,
                antal,
                utfdag_Sekretess.substring(0, 10) + " \n" + tomdkl_Sekretess,
                utfdag_Destruering.substring(0, 10) + " \n" + tomdkl_Destruering,
              ]);
            });
          });

          setItems(
            items.map((item) => {
              item[0] = (
                <Checkbox
                  key={item[1]}
                  className={classes.positionAbsolute1}
                  tabIndex={-1}
                  onChange={(event) => handleToggle(item[1], event.target.checked)}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              );
              return item;
            })
          );
        })
        .catch(console.log);
    }
  }, [selectedKund, hsnr, classes, handleToggle]);

  function generatePdf() {
    const orders = Object.keys(checked);

    const list = orders.filter((nr) => checked[nr]).join(";");
    if (!list) {
      return;
    }

    api.Reports.Sekretessintyg(selectedKund.kundnr, list)
      .then((response) => {
        var file = new Blob([response], { type: "application/pdf" });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
      })
      .catch(console.log);
  }

  const count = Object.values(checked).filter((value) => value).length;
  return (
    <div>
      <MainForm hideButtons showShortCutIntervals />

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <LockIcon />
              </CardIcon>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} className={classes.right}>
                  <Button onClick={generatePdf} color="primary">
                    ({count}) Skapa PDF intyg
                  </Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <Table
                striped
                tableHeaderColor="primary"
                tableHead={[
                  "Välj",
                  "Ordernr",
                  "Adress",
                  "Sekretessid",
                  "Beskrivning",
                  "Antal",
                  "Hämtad",
                  "Destruerad",
                ]}
                tableData={items}
                coloredColls={[]}
                colorsColls={["primary"]}
                customCellClasses={[classes.left]}
                customClassesForCells={[0]}
                customHeadCellClasses={[classes.left]}
                customHeadClassesForCells={[0]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
