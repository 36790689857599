export const sortByField = (field) => {
  return function compare(a, b) {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  };
};

export function sortNumbers(items) {
  items.sort((a, b) => a - b);
  return items;
}

export function sort(items) {
  items.sort();
  return items;
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function onlyUniqueByField(array, fieldName) {
  return array.filter((value, index, self) => {
    const compareValue = value[fieldName];
    return self.findIndex((item) => item[fieldName] === compareValue) === index;
  });
}
