import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import MoneyIcon from "@material-ui/icons/Money";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import api from "app/api";
import MainForm from "views/Dashboard/MainForm";
import { useKund } from "app/store";

const useStyles = makeStyles(styles);

// eslint-disable-next-line no-unused-vars
const sss = [
  {
    ordernr: 94624,
    hsadress: "Alströmergatan 12",
    sekretessrader: [
      {
        ordernr: 94624,
        hsadress: "Alströmergatan 12",
        sekretessid: " 101",
        beskrivning: "Sekretesspapper  Kärl 140 L",
        utfdag_Sekretess: "2020-10-26T00:00:00",
        tomdkl_Sekretess: "09:57",
        utfdag_Destruering: "2020-10-26T00:00:00",
        tomdkl_Destruering: "11:42",
        destruerad: true,
        antal: 1.0,
      },
    ],
  },
  {
    ordernr: 94069,
    hsadress: "Alströmergatan 12",
    sekretessrader: [
      {
        ordernr: 94069,
        hsadress: "Alströmergatan 12",
        sekretessid: " 216",
        beskrivning: "Sekretesspapper  Kärl 140 L",
        utfdag_Sekretess: "2020-10-19T00:00:00",
        tomdkl_Sekretess: "11:05",
        utfdag_Destruering: "2020-10-19T00:00:00",
        tomdkl_Destruering: "15:46",
        destruerad: true,
        antal: 1.0,
      },
    ],
  },
];

// https://minasidor.smartrecycling.se/api/api/Profile/NastaFranHs/1945/1

// https://minasidor.smartrecycling.se/api/api/Profile/ForegaendeFranHs/1945/1

export default function PricingPage() {
  const classes = useStyles();
  const [prices, setPrices] = React.useState([]);
  const [selectedKund] = useKund();

  React.useEffect(() => {
    if (selectedKund) {
      api.Tjanster.GetTjansterMedPrissatt(selectedKund.kundnr, 0)
        .then((items) => {
          const prices = [];
          items.forEach((item) => {
            item.prissattning.forEach((price) => {
              const { prisnr, beskrivning1, beskrivning2, aPris1 } = price;
              prices.push([prisnr, beskrivning1 + "\n" + beskrivning2, aPris1 + " Kr"]);
            });
          });
          setPrices(prices);
        })
        .catch(console.log);
    }
  }, [selectedKund]);

  return (
    <div>
      <MainForm hideDates />

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MoneyIcon />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <Table
                striped
                tableHeaderColor="primary"
                tableHead={["Prisnr", "Beskrivning", "Apris"]}
                tableData={prices}
                coloredColls={[]}
                colorsColls={["primary"]}
                customCellClasses={[classes.right, classes.left, classes.right]}
                customClassesForCells={[0, 1, 2]}
                customHeadCellClasses={[classes.right, classes.left, classes.right]}
                customHeadClassesForCells={[0, 1, 2]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
