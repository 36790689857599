import { useFastighet } from "app/store";
import { useKund } from "app/store";
import { useKunder } from "app/store";
import React from "react";
import SelectControl from "../SelectControl";
import { useGrupp } from "app/store";
import { useHamtstalle } from "app/store";
import { useFastigheter } from "app/store";
import { DEFALUT_HAMTSTALLE } from "app/store";
import { DEFAULT_FASTIGHET } from "app/store";
import { useGrupper } from "app/store";

export default function SelectKund() {
  const kunder = useKunder();
  const grupper = useGrupper();
  const [, setGrupp] = useGrupp();
  const [kund, setKund] = useKund();
  const [, setFastighet] = useFastighet();
  const [, setHamtstalle] = useHamtstalle();
  const fastigheter = useFastigheter();

  return (
    <SelectControl
      value={kund}
      id="kund-select"
      label="Välj kund"
      options={kunder}
      disableClearable
      onChange={
        kunder.length > 1
          ? (_, value) => {
              setKund(value);
              if (value && grupper.length > 1) {
                setGrupp(value.kundgrupp);
              }

              if (fastigheter.length > 1) {
                setFastighet(DEFAULT_FASTIGHET);
              }
              setHamtstalle(DEFALUT_HAMTSTALLE);
            }
          : undefined
      }
      getOptionSelected={(option, value) => value && value.kundnr === option.kundnr}
      getOptionLabel={(option) => {
        if (option.kundnr === "0") {
          return "Alla";
        }
        return option.namn && option.kundnr ? `${option.namn} (${option.kundnr})` : "";
      }}
    />
  );
}
