// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import BorderAllIcon from "@material-ui/icons/BorderAll";
import api from "app/api";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import { jsPDF } from "jspdf";
import React from "react";
import MainForm from "views/Dashboard/MainForm";
import esmartGreen from "assets/img/esmart-green";
import SignatureCanvas from "react-signature-canvas";
import Close from "@material-ui/icons/Close";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput";
import { useKund } from "app/store";
import { useHamtstalle } from "app/store";

// eslint-disable-next-line no-unused-vars
const a4 = { width: 210, height: 297 };

const useStyles = makeStyles(styles);

// eslint-disable-next-line no-unused-vars
const sss = [
  {
    ordernr: 92989,
    namn: "Accenture AB",
    personnr: "556608-0668",
    hsadress: "Alströmergatan 12",
    hspostadress: "Stockholm",
    hspostnr: "112 46",
    utfdag: "2020-10-05T00:00:00",
    tomdkl: "11:14",
    namN_KVITT: "Pontus Lindgren",
    namN_CHAUFFOR: "Patrik Finnermark",
    kontakt: "Christina Fava                                          ",
    atgard: null,
    mottagare: "Smart Recycling Sverige AB",
    mottagarE_ORGNR: "556774-5046",
    mottagarE_KOMMUN: "Stockholm",
    mottagarE_ADRESS: "Fagerstagatan 10, 163 53 Spånga",
    mottagarE_POSTNR: null,
    mottagarE_POSTORT: null,
    mottagarE_KONTAKT: null,
    transportor: null,
    transportoR_ORGNR: null,
    transportoR_KOMMUN: null,
    transportoR_ADRESS: null,
    transportoR_POSTNR: null,
    transportoR_POSTORT: null,
    transportoR_KONTAKT: null,
    rader: [
      {
        beskrivning: "Löst elektroniskt avfall",
        antal: 1.0,
        prisewcid: "20 01 35",
        fritext: null,
        faemballage: null,
        bestantal: 0.0,
        bestvikt: 0.0,
        behandlingsatt: null,
        nvvid: "x",
        nvviD_URSPR: "x",
      },
    ],
  },
];

export default function TransportPage() {
  const classes = useStyles();
  const aRef = React.useRef(null);
  const [href, setHref] = React.useState(null);
  const [items, setItems] = React.useState([]);
  const [signOrder, setSignOrder] = React.useState(null);
  const [nvvrapport, setNvvrapport] = React.useState(false);
  const [refetch, setRefetch] = React.useState(false);

  const [selectedKund] = useKund();
  const [selectedHamtstalle] = useHamtstalle();
  const location = (selectedHamtstalle || {}).location;

  function title(text, x, y, doc) {
    doc.setFont("helvetica", "bold");
    doc.setFontSize(20);
    doc.text(text, x, y);
  }

  function section(text, x, y, doc) {
    doc.setFont("helvetica", "bold");
    doc.setFontSize(13);
    doc.text(text, x, y);
  }

  function subtitle(text, x, y, doc) {
    doc.setFont("helvetica", "bold");
    doc.setFontSize(11);
    doc.text(text, x, y);
  }

  function body(text, x, y, doc, fontSize = 10) {
    doc.setFont("helvetica", "normal");
    doc.setFontSize(fontSize);
    doc.text(text || "", x, y);
  }

  function download(order, signature) {
    // eslint-disable-next-line no-unused-vars
    const sampleOrder = {
      ordernr: 73909,
      namn: "Accenture AB",
      personnr: "556608-0668",
      hsadress: "Fridhemsgatan 49",
      hspostadress: "Stockholm",
      hspostnr: "112 46",
      utfdag: "2020-01-15T00:00:00",
      tomdkl: "10:40",
      namN_KVITT: "Fredrik Armstedt",
      namN_CHAUFFOR: "Fredrik Armstedt",
      mottagare: "Smart Recycling Sverige AB",
      mottagarE_ORGNR: "556774-5046",
      mottagarE_KOMMUN: "Stockholm",
      mottagarE_ADRESS: "Fagerstagatan 10, 163 53 Spånga",
      rader: [
        { beskrivning: "Elektronik", antal: 138.0, prisewcid: "20 01 36" },
        { beskrivning: "Löst elektroniskt avfall", antal: 1.0, prisewcid: "20 01 36" },
      ],
    };

    // a4 = 210 × 297 mm
    // Default export is a4 paper, portrait, using millimeters for units

    const doc = new jsPDF({ orientation: "landscape" });

    const factor = 20;
    doc.addImage(esmartGreen, "PNG", 19, 13, 820 / factor, 390 / factor);

    // title
    title("Transportdokument för Farligt avfall", 90, 30, doc);
    //-------------------------------------------------------------
    doc.rect(20, 40, 257, 10);

    subtitle("Datum", 21, 40 + 4.5, doc);
    body(order.utfdag.substring(0, 10), 21, 40 + 4.5 * 2, doc);

    subtitle("CFAR", 21 + 85.7, 40 + 4.5, doc);
    body(order.avsandarE_CFARNR + "", 21 + 85.7, 40 + 4.5 * 2, doc);

    subtitle("Kommun", 21 + 85.7 * 2, 40 + 4.5, doc);
    body(order.hspostadress, 21 + 85.7 * 2, 40 + 4.5 * 2, doc);

    doc.line(20 + 85.7, 40, 20 + 85.7, 75);
    doc.line(20 + 85.7 * 2, 40, 20 + 85.7 * 2, 60);

    //-------------------------------------------------------------
    doc.rect(20, 50, 257, 10);
    subtitle("Avsändare", 21, 50 + 4.5, doc);
    body(order.namn, 21, 50 + 4.5 * 2, doc);

    subtitle("Organisationsnummer", 21 + 85.7, 50 + 4.5, doc);
    body(order.personnr, 21 + 85.7, 50 + 4.5 * 2, doc);

    subtitle("Kommunkod", 21 + 85.7 * 2, 50 + 4.5, doc);
    body(order.avsandarE_KOMMUNKOD, 21 + 85.7 * 2, 50 + 4.5 * 2, doc);
    //-------------------------------------------------------------
    doc.rect(20, 60, 257, 15);
    subtitle("Avsändare postadress", 21, 60 + 4.5, doc);
    body(order.hsadress, 21, 60 + 4.5 * 2, doc);
    body(order.hspostnr, 21, 60 + 4.5 * 3, doc);
    body(order.hspostadress, 21 + 12, 60 + 4.5 * 3, doc);
    doc.line(20 + 85.7 * 2, 50, 20 + 85.7 * 2, 60);
    // doc.line(20 + 85.7 * 2, 50, 20 + 85.7 * 2, 75);
    // doc.line(20 + 85.7, 50, 20 + 85.7, 75);

    subtitle("Underskrift Avsändare", 21 + 85.7, 60 + 4.5, doc);
    body("", 21 + 85.7, 60 + 4.5 * 2, doc);
    doc.addImage(signature, "PNG", 20 + 85.7 * 2, 60, 57, 20);

    //-------------------------------------------------------------
    section("Transportör", 20, 95 - 9 + 4.5, doc);
    doc.rect(20, 95, 257, 15);

    subtitle("Transportör", 21, 95 + 4.5, doc);
    body(order.mottagare, 21, 95 + 4.5 * 2, doc);

    subtitle("Organisationsnummer", 21 + 85.7, 95 + 4.5, doc);
    body(order.mottagarE_ORGNR, 21 + 85.7, 95 + 4.5 * 2, doc);

    subtitle("Kommunkod", 21 + 85.7 * 2, 95 + 4.5, doc);
    body(order.mottagarE_KOMMUNKOD || "", 21 + 85.7 * 2, 95 + 4.5 * 2, doc);
    //-------------------------------------------------------------

    doc.rect(20, 110, 257, 15);
    let parts = (order.mottagarE_ADRESS || " , ").split(",");
    subtitle("Transportör postadress", 21, 110 + 4.5, doc);
    body(parts.length > 0 ? parts[0].trim() : "", 21, 110 + 4.5 * 2, doc);
    body(parts.length > 1 ? parts[1].trim() : "", 21, 110 + 4.5 * 3, doc);

    subtitle("Underskrift Chaufför", 21 + 85.7, 110 + 4.5, doc);
    body(order.namN_CHAUFFOR, 21 + 85.7, 110 + 4.5 * 2, doc);
    doc.line(20 + 85.7 * 2, 95, 20 + 85.7 * 2, 110);
    doc.line(20 + 85.7, 95, 20 + 85.7, 125);

    // doc.addImage(signature, 'PNG', 20 + 85.7 * 2, 110, 57, 20);

    //-------------------------------------------------------------

    section("Mottagare", 20, 145 - 9 + 4.5, doc);
    doc.rect(20, 145, 257, 15);

    subtitle("Mottagare", 21, 145 + 4.5, doc);
    body(order.mottagare, 21, 145 + 4.5 * 2, doc);

    subtitle("Organisationsnummer", 21 + 85.7, 145 + 4.5, doc);
    body(order.mottagarE_ORGNR || "", 21 + 85.7, 145 + 4.5 * 2, doc);

    subtitle("Kommunkod", 21 + 85.7 * 2, 145 + 4.5, doc);
    body(order.mottagarE_KOMMUNKOD || "", 21 + 85.7 * 2, 145 + 4.5 * 2, doc);
    //-------------------------------------------------------------

    doc.rect(20, 160, 257, 15);

    parts = (order.mottagarE_ADRESS || " , ").split(",");

    subtitle("Mottagare postadress", 21, 160 + 4.5, doc);
    body(parts.length > 0 ? parts[0].trim() : "", 21, 160 + 4.5 * 2, doc);
    body(parts.length > 1 ? parts[1].trim() : "", 21, 160 + 4.5 * 3, doc);

    subtitle("Underskrift Mottagare", 21 + 85.7, 160 + 4.5, doc);
    body(order.namN_KVITT, 21 + 85.7, 160 + 4.5 * 2, doc);
    doc.line(20 + 85.7 * 2, 145, 20 + 85.7 * 2, 160);
    doc.line(20 + 85.7, 145, 20 + 85.7, 175);

    doc.addPage();

    doc.rect(20, 50, 257, 5);
    subtitle("AVFALLSSLAG", 22, 50 + 4.0, doc);
    subtitle("AVFALLSKOD/EWCKOD", 27 + 85.7 * 2, 50 + 4.0, doc);
    subtitle("MÄNGD/KG", 80 + 85.7 * 2, 50 + 4.0, doc);

    [order].forEach((item, i) => {
      // { beskrivning: 'Elektronik', antal: 138.0, prisewcid: '20 01 36' },

      //Annan kasserad elektrisk och elektronisk utrustning än den i 20 01 21 och 20 01 23 som innehåller farliga komponenter och enligt 2 kap. 3 § anses vara farligt avfall. Farliga komponenter från elektrisk och elektronisk utrustning kan omfatta sådana ackumulatorer och batterier som anges i 16 06 och som är märkta som farliga; kvicksilverbrytare, glas från katodstrålerör och annat aktiverat glas m.m.

      doc.rect(20, 55 + i * 15, 257, 15);

      if (item.rader.fritext) {
        const desc = doc.splitTextToSize(item.rader.fritext, 240);
        body(desc, 22, 45 + 4.5 * 3 + i * 15, doc, 8.5);
      } else {
        body(item.rader.beskrivning, 22, 50 + 4.5 * 3 + i * 15, doc, 8.5);
      }
      body(item.rader.prisewcid, 27 + 85.7 * 2, 50 + 4.5 * 3 + i * 15, doc);
      body(item.rader.antal + "", 80 + 85.7 * 2, 50 + 4.5 * 3 + i * 15, doc);
      doc.line(76 + 85.7 * 2, 50, 76 + 85.7 * 2, 70 + i * 15);
      doc.line(24 + 85.7 * 2, 50, 24 + 85.7 * 2, 70 + i * 15);
    });

    // doc.addImage(base64Logo, 'PNG', 120, 180, 57, 20);

    doc.save("a4.pdf");
  }

  function download1(order) {
    console.log("saved");

    var img = new Image();

    img.setAttribute("crossOrigin", "anonymous");

    img.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(this, 0, 0);

      var dataURL = canvas.toDataURL("image/png");

      download(order, dataURL);
      //alert(dataURL.replace(/^data:image\/(png|jpg);base64,/, ''));
    };

    img.src = order.avsandarE_SIGNATUR;
  }

  // https://minasidor.smartrecycling.se/api/api/Statistik/Transportdokument/139/
  React.useEffect(() => {
    if (selectedKund) {
      api.Statistik.Transportdokument(selectedKund.kundnr)
        .then((records) => {
          let values = [];

          records
            .filter((record) => {
              return !location || location === "Alla" || location === record.hsadress;
            })
            .forEach((record) => {
              record.rader.forEach((rader) => {
                values.push({ ...record, rader });
              });
            });

          values = values.filter((record) => {
            if (record.rader.beskrivning === "Löst elektroniskt avfall") {
              const utfdag = record.utfdag.substring(0, 10).replace(/-/g, "");

              if (utfdag >= "20210310") {
                return true;
              }

              return false;
            }

            return true;
          });

          values = values.map((record) => {
            let ja =
              record.rader.nvviD_URSPR &&
              record.rader.nvviD_URSPR !== "x" &&
              record.rader.nvvid &&
              record.rader.nvvid !== "x";

            return [
              record.ordernr,
              record.utfdag.substring(0, 10),
              record.hsadress,
              record.avsandarE_CFARNR,
              record.avsandarE_KOMMUNKOD,
              record.rader.prisewcid,
              record.rader.beskrivning,
              record.rader.antal + " kg",
              ja ? "JA" : "NEJ",
              record.rader.nvviD_URSPR || "x",
              record.rader.nvvid || "x",

              record.avsandarE_SIGNATUR ? (
                <a href="#" onClick={() => download1(record)}>
                  Hämta
                </a>
              ) : (
                <Button onClick={() => setSignOrder(record.ordernr)} color="primary" size="sm">
                  Signera dokument
                </Button>
              ),
            ];
          });

          values.sort((a, b) => b[1].localeCompare(a[1]));

          let csvHeader =
            [
              "Ordernr",
              "Datum",
              "Adress",
              "CFAR",
              "Kommunkod",
              "EWC-Kod",
              "Avfall",
              "Mängd",
              "Ombud",
              "Producent Avfalls-ID",
              "Insamling Avfalls-ID",
            ].join(";") + "\n";

          var universalBOM = "\uFEFF";

          const csvContent =
            csvHeader +
            values
              .map((item) => {
                const ar = [...item];
                ar.pop();
                return ar.join(";");
              })
              .join("\n");

          var encodedUri =
            "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM + csvContent);

          setHref(encodedUri);

          setItems(values);
        })
        .catch(console.log);
    }
  }, [selectedKund, location, refetch]);

  React.useEffect(() => {
    if (selectedKund) {
      api.Profile.My(selectedKund.kundnr)
        .then((res) => {
          setNvvrapport(res.nvvrapport);
        })
        .catch(console.log);
    }
  }, [selectedKund]);

  return (
    <div>
      <MainForm hideButtons showShortCutIntervals />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>

              <p style={{ padding: 10, borderRadius: 10, marginTop: 50, color: "#000" }}>
                Smart Recycling agerar ombud:
                <span style={{ color: "red" }}>
                  {nvvrapport ? "Aktiverat" : "Inaktiverat"}
                </span>{" "}
                <br />
                Vid önskemål agerar Smart Recycling som ombud för anteckning till Naturvårdsverket.
                För att aktivera denna tjäst, vänligen kontakta din säljare.
              </p>

              <p style={{ padding: 10, borderRadius: 10, backgroundColor: "#999" }}>
                Från och med november 2020 har verksamheter som är avfallsproducenter en skyldighet
                att anteckna och rapportera uppgifter om farligt avfall in till Naturvårdsverkets
                nya elektroniska avfallsregister. I vår kundportal eSmart kan ni ta del av samtlig
                information och översikt för varje utförd transport.
              </p>

              <div>
                <Button
                  color="primary"
                  onClick={() => {
                    setTimeout(() => aRef.current.click(), 10);
                  }}
                  loading>
                  <BorderAllIcon /> Export as Excel
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <Table
                striped
                tableHeaderColor="primary"
                tableHead={[
                  "Ordernr",
                  "Datum",
                  "Adress",
                  "CFAR",
                  "Kommunkod",
                  "EWC-Kod",
                  "Avfall",
                  "Mängd",
                  "Ombud",
                  "Producent Avfalls-ID",
                  "Insamling Avfalls-ID",
                  "PDF",
                ]}
                tableData={items}
                coloredColls={[0]}
                colorsColls={["primary"]}
                customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
                customCellClasses={[
                  classes.center,
                  classes.left,
                  classes.left,
                  classes.left,
                  classes.left,
                  classes.left,
                  classes.left,
                  classes.right,
                ]}
                customHeadCellClasses={[
                  classes.center,
                  classes.left,
                  classes.left,
                  classes.left,
                  classes.left,
                  classes.left,
                  classes.left,
                  classes.right,
                ]}
                customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <a style={{ display: "none" }} ref={aRef} href={href} download="farligt-avfall.csv">
        Download
      </a>

      <SignatureDialog
        onClose={() => {
          setRefetch(!refetch);
          setSignOrder(null);
        }}
        {...{ classes, ordernr: signOrder, selectedKund }}
      />
    </div>
  );
}

function SignatureDialog({ ordernr, classes, selectedKund, onClose }) {
  const canvasRef = React.useRef(null);
  const [fornamn, setFornamn] = React.useState("");
  const [efternamn, setEfternamn] = React.useState("");

  function onClearSignature() {
    canvasRef.current.clear();
  }

  function onSaveSignature() {
    const Kundnr = selectedKund.kundnr;
    const Svg = canvasRef.current.toDataURL("image/svg+xml");
    api.FA.Signera(Kundnr, ordernr, Svg, fornamn, efternamn)
      .then(() => {
        canvasRef.current.clear();
        setEfternamn("");
        setFornamn("");
        onClose();
      })
      .catch((error) => alert(error.message));
  }

  return (
    <Dialog open={!!ordernr} keepMounted onClose={onClose}>
      <DialogTitle disableTypography id="classic-modal-slide-title" className={classes.modalHeader}>
        <h4 className={classes.modalTitle}>Signatur ordernr: {ordernr}</h4>

        <Button
          justIcon
          color="transparent"
          onClick={onClose}
          style={{ position: "absolute", right: 8, top: 8 }}>
          <Close />
        </Button>
      </DialogTitle>
      <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
        <div style={{ border: "1px solid red", backgroundColor: "#ccc" }}>
          <SignatureCanvas
            ref={canvasRef}
            penColor="black"
            backgroundColor="#fff"
            canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
          />
          <div></div>
        </div>

        <CustomInput
          id="namn"
          labelText="Förnamn"
          formControlProps={{ fullWidth: true }}
          inputProps={{
            onChange: ({ target }) => setFornamn(target.value),
          }}
        />

        <CustomInput
          id="namn"
          labelText="Efternamn"
          formControlProps={{ fullWidth: true }}
          inputProps={{
            onChange: ({ target }) => setEfternamn(target.value),
          }}
        />
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button color="warning" onClick={onClearSignature}>
          Rensa
        </Button>
        <Button color="primary" onClick={onSaveSignature}>
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  );
}
