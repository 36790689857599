import { useFastighet, useFastigheter } from "app/store";
import SelectControl from "../SelectControl";
import React from "react";
import { useKunder } from "app/store";
import { useKund } from "app/store";
import { useHamtstalle } from "app/store";
import { useGrupp } from "app/store";
import { DEFALUT_HAMTSTALLE } from "app/store";

export default function SelectFastighet() {
  const kunder = useKunder();
  const [kund, setKund] = useKund();
  const [, setGrupp] = useGrupp();
  const fastigheter = useFastigheter();
  const [, setHamtstalle] = useHamtstalle();
  const [fastighet, setFastighet] = useFastighet();

  return (
    <SelectControl
      value={fastighet}
      options={fastigheter}
      disableClearable
      id="fastighet-select"
      label="Välj fastighet"
      getOptionLabel={(option) => option.namn}
      onChange={
        fastigheter.length > 1
          ? (_, newValue) => {
              if (newValue && newValue.namn !== "Alla") {
                const kundnr = newValue.kunder;

                // eslint-disable-next-line eqeqeq
                const newKund = kunder.find((k) => k.kundnr == kundnr);

                const readonly = kund && kund.readonly;
                if (!readonly && newKund) {
                  setKund(newKund);
                  setGrupp(newKund.kundgrupp);
                  setHamtstalle(DEFALUT_HAMTSTALLE);
                }
              }
              if (fastigheter.length > 1) {
                setFastighet(newValue);
              }
            }
          : undefined
      }
    />
  );
}
