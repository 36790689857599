import React from "react";
import { getCo2MaterialPerMonthData } from "./charts";
import useBesparingCO2 from "./useBesparingCO2";
import useBesparingCO2Korstracka from "./useBesparingCO2Korstracka";

export default function useBesparingCO2Total() {
  const besparingCO2 = useBesparingCO2();
  const besparingCO2Korstracka = useBesparingCO2Korstracka();

  const besparingCO2Total = React.useMemo(() => {
    if (besparingCO2Korstracka && besparingCO2) {
      const chart2 = getCo2MaterialPerMonthData(besparingCO2);

      const chart = {
        ...besparingCO2Korstracka,
        labels: besparingCO2Korstracka.labels,
        datasets: besparingCO2Korstracka.datasets.map((item, i) => {
          const item2 = chart2.datasets[i];

          return {
            ...item,
            data: (item.data || []).map((value, index) => value + (item2 ? item2.data[index] : 0)),
          };
        }),
      };

      return chart;
    }
    return null;
  }, [besparingCO2Korstracka, besparingCO2]);

  return besparingCO2Total;
}
