import { useGrupp, useGrupper } from "app/store";
import SelectControl from "../SelectControl";
import React from "react";
import { useKund } from "app/store";
import { useHamtstalle } from "app/store";
import { useFastighet } from "app/store";
import { useFastigheter } from "app/store";

export default function SelectGrupp() {
  const grupper = useGrupper();
  const [grupp, setGrupp] = useGrupp();

  const [kund, setKund] = useKund();
  const [, setFastighet] = useFastighet();
  const [, setHamtstalle] = useHamtstalle();
  const fastigheter = useFastigheter();

  return (
    <SelectControl
      value={grupp}
      disabled={grupper.length < 2}
      options={grupper}
      disableClearable
      id="grupp-select"
      label="Välj grupp"
      getOptionLabel={(option) => option}
      onChange={
        grupper.length > 1
          ? (_, newValue) => {
              setGrupp(newValue);
              if (fastigheter.length > 1) {
                setFastighet("Alla");
              }
              setHamtstalle({ id: 0, location: "Alla" });

              const readonly = kund && kund.readonly;
              if (!readonly) {
                setKund({ kundnr: "0", namn: "Alla", kundgrupp: "--" });
              }
            }
          : undefined
      }
    />
  );
}
