import { atom, useAtom } from "jotai";
import React from "react";
import { useQuery } from "react-query";

import api from "./api";
import { Store } from "./auth";
import { onlyUnique, onlyUniqueByField, sort, sortByField } from "./util";

// ---------------------------------------------

const gruppAtom = atom("Alla");
export function useGrupp() {
  return useAtom(gruppAtom);
}

export const DEFAULT_KUND = { kundnr: "0", namn: "Alla", kundgrupp: "--" };
const kundAtom = atom(DEFAULT_KUND);
export function useKund() {
  return useAtom(kundAtom);
}

export const DEFAULT_FASTIGHET = { kundnr: "0", namn: "Alla", fastighet: "", kundgrupp: "--" };
const fastighetAtom = atom(DEFAULT_FASTIGHET);
export function useFastighet() {
  return useAtom(fastighetAtom);
}

export const DEFALUT_HAMTSTALLE = {
  id: 0,
  kundnr: "0",
  location: "Alla",
  fastighet: "",
  kundgrupp: "--",
};
const hamtstalleAtom = atom(DEFALUT_HAMTSTALLE);
export function useHamtstalle() {
  return useAtom(hamtstalleAtom);
}

// ---------------------------------------------

export function useGrupper() {
  const [allaKunder] = useAllaKunder();
  const [, setGrupp] = useGrupp();
  const [grupper, setGrupper] = React.useState([]);

  React.useEffect(() => {
    const items = sort(allaKunder.map((kund) => kund.kundgrupp).filter(onlyUnique));
    if (items.length === 1) {
      setGrupp(items[0]);
      setGrupper(items);
    } else {
      setGrupper(["Alla", ...items]);
    }
  }, [allaKunder]);

  return grupper;
}

export function useKunder() {
  const [grupp] = useGrupp();
  const [, setKund] = useKund();
  const [allaKunder] = useAllaKunder();

  const kunder = React.useMemo(() => {
    if (allaKunder) {
      const kunderMap = {};
      allaKunder.forEach((kund) => (kunderMap[kund.namn + ":" + kund.kundnr] = kund));

      const items = Object.values(kunderMap).filter(
        (kund) => grupp === "Alla" || kund.kundgrupp === grupp
      );
      items.sort(sortByField("namn"));

      if (items.length === 1) {
        setKund({ ...items[0], readonly: true });
        return items;
      }

      return [DEFAULT_KUND, ...items];
    }

    return [];
  }, [grupp, allaKunder]);

  return kunder;
}

export function useFastigheter() {
  const [kund] = useKund();
  const [grupp] = useGrupp();
  const [allaKunder] = useAllaKunder();
  const [, setFastighet] = useFastighet();
  const [fastigheter, setFastigheter] = React.useState([]);

  React.useEffect(() => {
    if (allaKunder) {
      const items = sort(
        onlyUniqueByField(
          allaKunder
            .filter((kund) => kund.fastighet)
            .filter((kund) => grupp === "Alla" || kund.kundgrupp === grupp)
            .filter((k) => kund.namn === "Alla" || k.kundnr === kund.kundnr)
            .map((kund) => ({
              kundnr: kund.kundnr,
              fastighet: kund.fastighet,
              namn: `${kund.fastighet} (${kund.kundnr})`,
              kundgrupp: kund.kundgrupp,
            })),
          "namn"
        )
      );

      if (items.length === 1) {
        setFastighet(items[0]);
        setFastigheter(items);
      } else {
        setFastighet(DEFAULT_FASTIGHET);
        setFastigheter([DEFAULT_FASTIGHET, ...items]);
      }
    }
  }, [kund, grupp, allaKunder]);

  return fastigheter;
}

export function useHamtstallen() {
  const [kund] = useKund();
  const [grupp] = useGrupp();
  const [fastighet] = useFastighet();

  const [, setHamtstalle] = useHamtstalle();
  const [hamtstallen, setHamtstallen] = React.useState([]);

  const { isLoading, data } = useQuery(["hamtstallen", kund?.kundnr, grupp], () =>
    kund?.kundnr ? api.Profile.Locations(kund.kundnr, grupp || "--") : []
  );

  React.useEffect(() => {
    if (!isLoading && data) {
      const items = data.filter(
        (item) => fastighet.namn === "Alla" || `${item.fastbet} (${kund.kundnr})` === fastighet.namn
      );
      if (items.length === 1) {
        setHamtstallen([...items]);
        setHamtstalle(items[0]);
      } else {
        setHamtstalle(DEFALUT_HAMTSTALLE);
        setHamtstallen([DEFALUT_HAMTSTALLE, ...items]);
      }
    }
  }, [data, fastighet, isLoading]);

  return hamtstallen;
}

// ---------------------------------------------

const readyAtom = atom(false);

export function useReady() {
  return useAtom(readyAtom);
}

const kontaktAtom = atom(null);

export function useKontakt() {
  return useAtom(kontaktAtom);
}

const allaKunderAtom = atom([]);

export function useAllaKunder() {
  return useAtom(allaKunderAtom);
}

const selectedIntervalAtom = atom("3_months");

export function useSelectedInterval() {
  return useAtom(selectedIntervalAtom);
}

const selectedGraphsAtom = atom([]);

export function useSelectedGraphs() {
  const [selectedGraphs, setSelectedGraphs] = useAtom(selectedGraphsAtom);

  function addToSelectedGraphs(item) {
    // if item is already in selectedGraphs, remove it
    if (selectedGraphs.includes(item)) {
      setSelectedGraphs(selectedGraphs.filter((i) => i !== item));
    } else {
      // if item is not in selectedGraphs, add it
      setSelectedGraphs([...selectedGraphs, item]);
    }
  }

  return { selectedGraphs, setSelectedGraphs, addToSelectedGraphs };
}

export function useInfo() {
  const [kund] = useKund();
  return useQuery(["info", kund.kundnr], () => (Store.token ? api.Profile.Info(kund.kundnr) : {}));
}

export function useMy() {
  const [kund] = useKund();
  const { data } = useQuery(
    ["my", kund.kundnr],
    Store.token ? () => api.Profile.My(kund.kundnr) : {}
  );
  return data;
}

const toDateAtom = atom(new Date().toJSON().substring(0, 10));

export function useToDate() {
  return useAtom(toDateAtom);
}

const fromDateAtom = atom(prevMonth(new Date()).toJSON().substring(0, 10));

export function useFromDate() {
  return useAtom(fromDateAtom);
}

function prevMonth(date) {
  var thisMonth = date.getMonth();
  date.setMonth(thisMonth - 3);
  if (
    date.getMonth() !== thisMonth - 3 &&
    (date.getMonth() !== 11 || (thisMonth === 11 && date.getDate() === 1))
  ) {
    date.setDate(0);
  }

  return date;
}
