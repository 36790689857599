import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EventDialog from "views/Calendar/EventDialog";
import { Button as MuiButton, Card } from "@material-ui/core";
import SelectControl from "views/Dashboard/SelectControl";
import Button from "components/CustomButtons/Button.js";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import { generatePdf } from "views/Calendar/pdf";

const useStyles = makeStyles(styles);

export default function CalendarListView({
  dates,
  events,
  filters,
  pastDates,
  selectedPast,
  selectedFilter,
  selectedPeriod,
  setSelectedPast,
  setSelectedFilter,
  setSelectedPeriod,
}) {
  const classes = useStyles();

  const [past, setPast] = React.useState([]);
  const [future, setFuture] = React.useState([]);

  const [selectedEvent, setSelectedEvent] = React.useState(null);

  React.useEffect(() => {
    const now = new Date().toJSON().substring(0, 10).replaceAll("-", "");
    setFuture(events.filter((e) => e.date >= now));
    setPast(events.filter((e) => e.date < now && e.date >= selectedPast.date));
  }, [events, selectedPast.date]);

  let list = past.filter((event) => {
    if (!selectedFilter || selectedFilter === "Alla ordrar") {
      return true;
    }

    if (selectedFilter === "Alla avvikelser") {
      return event.avvikelser_ids;
    }

    return event.avvikelser_ids.includes(selectedFilter);
  });

  return (
    <div>
      <h3 style={{ marginBottom: 4, paddingLeft: 8 }}>Hämtningsschema</h3>

      <Card style={{ padding: 16 }}>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <div
              style={{
                margin: 8,
                paddingTop: 8,
                paddingLeft: 16,
                paddingRight: 16,
                paddingBottom: 8,
                background: "#fff",
                display: "inline-block",
              }}>
              Visar endast schemalagda produkter för tömning
            </div>
          </GridItem>
          <GridItem xs={3} sm={3} md={3}>
            <SelectControl
              options={dates}
              disableClearable
              id="kontakt-select"
              label="Välj filter"
              value={selectedPeriod}
              getOptionLabel={(option) => option.label}
              onChange={(_, value) => setSelectedPeriod(value)}
            />
          </GridItem>

          <GridItem xs={3} sm={3} md={3} style={{ textAlign: "right" }}>
            <Button
              style={{ backgroundColor: "#F44435", marginTop: 8 }}
              color="primary"
              variant="contained"
              onClick={() =>
                generatePdf({
                  events: future,
                  period: selectedPeriod,
                  title: "Hämtningsschema",
                })
              }>
              <PictureAsPdfIcon /> Skapa PDF
            </Button>
          </GridItem>
        </GridContainer>

        <TableList noDeviation events={future} onEvent={setSelectedEvent} />
      </Card>

      <h3 style={{ marginBottom: 4, paddingLeft: 8, marginTop: 48 }}>Historik</h3>

      <Card style={{ padding: 16 }}>
        <GridContainer>
          <GridItem xs={3} sm={3} md={3}>
            <SelectControl
              disableClearable
              options={filters}
              id="kontakt-select"
              label="Välj filter"
              value={selectedFilter}
              onChange={(event, value) => setSelectedFilter(value)}
              getOptionLabel={(option) => option}
            />
          </GridItem>

          <GridItem xs={3} sm={3} md={3}>
            <SelectControl
              disableClearable
              id="kontakt-select"
              label="Välj filter"
              options={pastDates}
              value={selectedPast}
              getOptionLabel={(option) => option.label}
              onChange={(event, value) => setSelectedPast(value)}
            />
          </GridItem>
        </GridContainer>

        <TableList style={{ marginTop: 32 }} events={list} onEvent={setSelectedEvent} />
      </Card>
      {selectedEvent && (
        <EventDialog
          {...{ classes }}
          event={selectedEvent}
          onClose={() => setSelectedEvent(null)}
        />
      )}
    </div>
  );
}

function TableList({ style, noDeviation, events, onEvent, starDate }) {
  return (
    <GridContainer style={style}>
      <GridItem xs={12} sm={12} md={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Datum {starDate ? "*" : ""}</TableCell>
              <TableCell>Vecka</TableCell>
              <TableCell>Veckodag</TableCell>
              <TableCell>Beskrivning</TableCell>
              {noDeviation ? null : <TableCell>Avvikelser</TableCell>}
              <TableCell>Detaljer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((event, index) => (
              <TableRow key={index} onClick={() => onEvent(event)}>
                <TableCell style={{ verticalAlign: "top" }}>
                  {event.start.substring(0, 10)}
                </TableCell>
                <TableCell style={{ verticalAlign: "top" }}>{event.vecka}</TableCell>
                <TableCell style={{ verticalAlign: "top" }}>{event.veckodag}</TableCell>
                <TableCell style={{ verticalAlign: "top" }}>{event.title}</TableCell>
                {noDeviation ? null : (
                  <TableCell
                    style={{ verticalAlign: "top" }}
                    title={event.avvikelser_ids ? "Avvikelser" : undefined}>
                    {event.avvikelser_ids}
                  </TableCell>
                )}
                <TableCell style={{ verticalAlign: "top" }}>
                  <MuiButton style={{ color: "#F44435" }} variant="text">
                    Detaljer
                  </MuiButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </GridItem>
    </GridContainer>
  );
}
