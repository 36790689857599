// @material-ui/core components
import Button from "components/CustomButtons/Button.js";

import React from "react";
import Close from "@material-ui/icons/Close";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

export default function ArticleDialog({ classes, article, onClose }) {
  return (
    <Dialog open keepMounted onClose={onClose}>
      <DialogTitle disableTypography id="classic-modal-slide-title" className={classes.modalHeader}>
        <h4 className={classes.modalTitle}>{article.title.rendered}</h4>

        <Button
          justIcon
          color="transparent"
          onClick={onClose}
          style={{ position: "absolute", right: 8, top: 8 }}>
          <Close />
        </Button>
      </DialogTitle>
      <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
        <img
          alt=""
          style={{ width: "100%", marginBottom: 16 }}
          src={article.yoast_head_json.og_image[0].url}
        />
        <div dangerouslySetInnerHTML={{ __html: article.content.rendered }} />
      </DialogContent>
    </Dialog>
  );
}
