import Button from "components/CustomButtons/Button.js";

import React from "react";
import Close from "@material-ui/icons/Close";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

export default function EventDialog({ classes, event, onClose }) {
  return (
    <Dialog open keepMounted onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle disableTypography id="classic-modal-slide-title" className={classes.modalHeader}>
        <Table style={{ marginTop: 48, border: "1px solid #ccc" }}>
          <TableBody>
            <TableRow>
              <TableCell style={{ width: 75, backgroundColor: "#f7f7f7" }}>Datum</TableCell>
              <TableCell>{event.start.substring(0, 10)}</TableCell>

              <TableCell style={{ width: 75, backgroundColor: "#f7f7f7" }}>Ordernr</TableCell>
              <TableCell>{event.ordernr || "?"}</TableCell>

              <TableCell style={{ width: 75, backgroundColor: "#f7f7f7" }}>Adress</TableCell>
              <TableCell>{event.hsadress}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Button
          justIcon
          color="transparent"
          onClick={onClose}
          style={{ position: "absolute", right: 8, top: 8 }}>
          <Close />
        </Button>
      </DialogTitle>
      <DialogContent
        style={{ paddingBottom: 48 }}
        id="classic-modal-slide-description"
        className={classes.modalBody}>
        <Table style={{ border: "1px solid #ccc" }}>
          <TableHead>
            <TableRow style={{ backgroundColor: "#f7f7f7" }}>
              <TableCell>Beskrivning</TableCell>
              <TableCell>Typ</TableCell>
              <TableCell>Antal</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {event.orderrader.map((row, idx) => (
              <TableRow key={idx}>
                <TableCell>{row.beskrivninG1}</TableCell>
                <TableCell>{row.beskrivninG2}</TableCell>
                <TableCell>{row.antal}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {event.avvikelser.length > 0 && (
          <div>
            <h4 style={{ marginTop: 24 }}>Avvikelser</h4>
            <Table style={{ border: "1px solid #ccc" }}>
              <TableHead>
                <TableRow style={{ backgroundColor: "#f7f7f7" }}>
                  <TableCell>ID</TableCell>
                  <TableCell>Dag</TableCell>
                  <TableCell>Tid</TableCell>
                  <TableCell>Beskrivning</TableCell>
                  <TableCell>Åtgärd</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {event.avvikelser.map((row, idx) => (
                  <>
                    <TableRow key={idx}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.atgardsdag.substring(0, 10)}</TableCell>
                      <TableCell>{row.atgardstid}</TableCell>
                      <TableCell>{row.beskrivning}</TableCell>
                      <TableCell>{row.atgardstext}</TableCell>
                    </TableRow>
                    {row.bilder.length > 0 && (
                      <TableRow key={idx}>
                        <TableCell colSpan={5}>
                          {row.bilder.map((bild, key) => (
                            <div key={key}>
                              <img
                                alt=""
                                src={bild.source}
                                style={{ display: "block", width: "100%", marginTop: 16 }}
                              />
                            </div>
                          ))}
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
