import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";

export default function SelectControl({
  id,
  value,
  label,
  options,
  onChange,
  disabled,
  defaultValue,
  getOptionLabel,
  disableClearable,
  getOptionSelected,
}) {
  return (
    <Autocomplete
      {...{
        id,
        value,
        options,
        disabled,
        onChange,
        defaultValue,
        getOptionLabel,
        disableClearable,
        getOptionSelected,
      }}
      renderInput={(params) => <TextField mt={8} {...params} label={label} variant="outlined" />}
    />
  );
}
