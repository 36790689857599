// @material-ui/core components
import "chartjs-plugin-datalabels";
// core components
import api from "app/api";
import Card from "components/Card/Card";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import ArticleDialog from "./ArticleDialog";

export default function News() {
  const [posts, setPosts] = React.useState([]);
  const [article, setArticle] = React.useState(null);

  React.useEffect(() => {
    api.News.posts().then(setPosts).catch(console.log);
  }, []);

  return (
    <div>
      <h2 style={{ marginBottom: -8, paddingLeft: 8 }}>Nyheter</h2>
      <GridContainer>
        {posts.map((post) => (
          <GridItem xs={4} sm={4} md={4} key={post.id}>
            <Card style={{ padding: 16, cursor: "pointer" }} onClick={() => setArticle(post)}>
              <h4 style={{ minHeight: 50 }}>{post.title.rendered}</h4>
              <img
                alt=""
                style={{
                  height: 220,
                  width: "100%",
                  marginBottom: 16,
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                src={post.yoast_head_json.og_image[0].url}
              />
              <div
                style={{
                  height: 40,
                  overflow: "hidden",
                  WebkitLineClamp: 2,
                  display: "-webkit-box",
                  textOverflow: "ellipsis",
                  WebkitBoxOrient: "vertical",
                }}
                dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
              />
            </Card>
          </GridItem>
        ))}
      </GridContainer>

      <br />
      {article ? (
        <ArticleDialog {...{ article }} onClose={() => setArticle(null)} classes={{}} />
      ) : null}
    </div>
  );
}
