/* eslint-disable no-dupe-keys */
import batterier_png from "assets/img/materials/batterier_png";
import blandat_glas_png from "assets/img/materials/blandat_glas_png";
import brannbart_png from "assets/img/materials/brannbart_png";
import deponi_png from "assets/img/materials/deponi_png";
import elektronik_png from "assets/img/materials/elektronik_png";
import fargat_glas_png from "assets/img/materials/fargat_glas_png";
import farligt_avfall_png from "assets/img/materials/farligt_avfall_png";
import glodlampor_png from "assets/img/materials/glodlampor_png";
import hardplast_png from "assets/img/materials/hardplast_png";
import lysror_png from "assets/img/materials/lysror_png";
import metall_png from "assets/img/materials/metall_png";
import mjukplast_png from "assets/img/materials/mjukplast_png";
import ofargat_glas_png from "assets/img/materials/ofargat_glas_png";
import other_png from "assets/img/materials/other_png";
import pallkrage_png from "assets/img/materials/pallkrage_png";
import pall_png from "assets/img/materials/pall_png";
import pant_png from "assets/img/materials/pant_png";
import papper_png from "assets/img/materials/papper_png";
import plast_png from "assets/img/materials/plast_png";
import sekretesselektronik_png from "assets/img/materials/sekretesselektronik_png";
import sekretesspapper_png from "assets/img/materials/sekretesspapper_png";
import spackel_png from "assets/img/materials/spackel_png";
import textilier_png from "assets/img/materials/textilier_png";
import toners_png from "assets/img/materials/toners_png";
import tra_png from "assets/img/materials/tra_png";
import vitvaror_png from "assets/img/materials/vitvaror_png";
import wellpapp_png from "assets/img/materials/wellpapp_png";

export const nameToIconFileMap = {
  Batterier: "batterier.png",
  "Glas blandat": "blandat_glas.png",
  brännbart: "brannbart.png",
  Deponi: "deponi.png",
  Elektronik: "elektronik.png",
  "Färgat Glas": "fargat_glas.png",
  ssssss: "farligt_avfall.png",
  Glödlampor: "glodlampor.png",
  ssssss: "hardplast.png",
  Lysrör: "lysror.png",
  Metall: "metall.png",
  MjukPlast: "mjukplast.png",
  "Ofärgat Glas": "ofargat_glas.png",
  Pall: "pall.png",
  ssssss: "pallkrage.png",
  Pant: "pant.png",
  Papper: "papper.png",
  Plast: "plast.png",
  ssssss: "sekretesselektronik.png",
  Sekretesspapper: "sekretesspapper.png",
  ssssss: "spackel.png",
  ssssss: "textilier.png",
  Toners: "toners.png",
  ssssss: "tra.png",
  ssssss: "vitvaror.png",
  "Wellpapp/kartong": "wellpapp.png",
  other: "other.png",
};

export const iconFileToImageSrcMap = {
  "batterier.png": require("assets/img/materials/batterier.png"),
  "blandat_glas.png": require("assets/img/materials/blandat_glas.png"),
  "brannbart.png": require("assets/img/materials/brannbart.png"),
  "deponi.png": require("assets/img/materials/deponi.png"),
  "elektronik.png": require("assets/img/materials/elektronik.png"),
  "fargat_glas.png": require("assets/img/materials/fargat_glas.png"),
  "farligt_avfall.png": require("assets/img/materials/farligt_avfall.png"),
  "glodlampor.png": require("assets/img/materials/glodlampor.png"),
  "hardplast.png": require("assets/img/materials/hardplast.png"),
  "lysror.png": require("assets/img/materials/lysror.png"),
  "metall.png": require("assets/img/materials/metall.png"),
  "mjukplast.png": require("assets/img/materials/mjukplast.png"),
  "ofargat_glas.png": require("assets/img/materials/ofargat_glas.png"),
  "pall.png": require("assets/img/materials/pall.png"),
  "pallkrage.png": require("assets/img/materials/pallkrage.png"),
  "pant.png": require("assets/img/materials/pant.png"),
  "papper.png": require("assets/img/materials/papper.png"),
  "plast.png": require("assets/img/materials/plast.png"),
  "sekretesselektronik.png": require("assets/img/materials/sekretesselektronik.png"),
  "sekretesspapper.png": require("assets/img/materials/sekretesspapper.png"),
  "spackel.png": require("assets/img/materials/spackel.png"),
  "textilier.png": require("assets/img/materials/textilier.png"),
  "toners.png": require("assets/img/materials/toners.png"),
  "tra.png": require("assets/img/materials/tra.png"),
  "vitvaror.png": require("assets/img/materials/vitvaror.png"),
  "wellpapp.png": require("assets/img/materials/wellpapp.png"),
  "other.png": require("assets/img/materials/other.png"),
};

export const nameToIconBase64Map = {
  Batterier: batterier_png,
  "Glas blandat": blandat_glas_png,
  brännbart: brannbart_png,
  Deponi: deponi_png,
  Elektronik: elektronik_png,
  "Färgat Glas": fargat_glas_png,
  ssssss: farligt_avfall_png,
  Glödlampor: glodlampor_png,
  ssssss: hardplast_png,
  Lysrör: lysror_png,
  Metall: metall_png,
  MjukPlast: mjukplast_png,
  "Ofärgat Glas": ofargat_glas_png,
  Pall: pall_png,
  ssssss: pallkrage_png,
  Pant: pant_png,
  Papper: papper_png,
  Plast: plast_png,
  ssssss: sekretesselektronik_png,
  Sekretesspapper: sekretesspapper_png,
  ssssss: spackel_png,
  ssssss: textilier_png,
  Toners: toners_png,
  ssssss: tra_png,
  ssssss: vitvaror_png,
  "Wellpapp/kartong": wellpapp_png,
  other: other_png,
};
