/*eslint-disable*/
import React from "react";
// react components used to create a calendar with events on it
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";
// react component used to create alerts

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import api from "app/api";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { Menu } from "@material-ui/icons";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { useHamtstalle, useKund } from "app/store";
import Button from "components/CustomButtons/Button.js";
import MainForm from "views/Dashboard/MainForm";
import CalendarListView from "views/Pages/CalendarListView";
import Logo from "views/Pages/Logo";

moment.locale("sv-SE", {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});
const localizer = momentLocalizer(moment);

const localStyles = makeStyles({
  dot: {
    width: "10px",
    height: "10px",
    cursor: "pointer",
    borderRadius: "5px",
    backgroundColor: "#F44435",
  },
  container: {
    borderWidth: "1px",
  },
});

const oneMonthBack = moment()
  .subtract(1, "month")
  .toDate()
  .toJSON()
  .substring(0, 10)
  .replaceAll("-", "");

const oneYearBack = moment()
  .subtract(1, "year")
  .toDate()
  .toJSON()
  .substring(0, 10)
  .replaceAll("-", "");

const sixMonthsAhead = moment()
  .add(6, "months")
  .toDate()
  .toJSON()
  .substring(0, 10)
  .replaceAll("-", "");

const threeMonthsAhead = moment()
  .add(3, "months")
  .toDate()
  .toJSON()
  .substring(0, 10)
  .replaceAll("-", "");

const twoWeeksAhead = moment()
  .add(2, "weeks")
  .toDate()
  .toJSON()
  .substring(0, 10)
  .replaceAll("-", "");

const oneMonthAhead = moment()
  .add(1, "months")
  .toDate()
  .toJSON()
  .substring(0, 10)
  .replaceAll("-", "");

const dates = [
  { label: "2 Veckor", date: twoWeeksAhead },
  { label: "1 Månad", date: oneMonthAhead },
  { label: "3 Månader", date: threeMonthsAhead },
  { label: "6 Månader", date: sixMonthsAhead },
];

const pastDates = [
  { label: "1 Månad", date: oneMonthBack },
  { label: "12 Månader", date: oneYearBack },
  { label: "24 Månader", date: "20000101" },
];

const dMap = {
  STÄNGT: true,
  FELSORT20: true,
  FELSORT40: true,
  FELSORT60: true,
  FELSORT80: true,
  LÖSTAVFALL: true,
  FELSORTERING: true,
  LÖSTELEKTRONIK: true,
};

export default function Calendar() {
  const localClasses = localStyles();
  const [alert, setAlert] = React.useState(null);
  const [events, setEvents] = React.useState([]);
  const [viewAs, setViewAs] = React.useState("list");
  const [loading, setLoading] = React.useState(false);
  const [selectedPeriod, setSelectedPeriod] = React.useState(dates[0]);
  const [selectedPast, setSelectedPast] = React.useState(pastDates[0]);

  const [filters, setFilters] = React.useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState("Alla ordrar");

  const selectedEvent = (event) => {
    window.alert(event.title);
  };

  const [selectedKund] = useKund();
  const [selectedHamtstalle] = useHamtstalle();
  const hsnr = (selectedHamtstalle || { id: 0 }).id;

  React.useEffect(() => {
    if (selectedKund) {
      setLoading(true);
      api.Tjanster.GetTurschemaFranHs(selectedKund.kundnr, hsnr)
        .then((data) => {
          const filterMap = {};

          const items = data
            .map((event) => {
              const avvikelser = event.avvikelser.filter((row) => {
                return (
                  dMap[row.id.trim()] && row.datum.substring(0, 10).replaceAll("-", "") > 20240307
                );
              });

              avvikelser.forEach((a) => (filterMap[a.id.trim()] = true));

              return {
                ...event,
                avvikelser,
                color: "azure",
                date: event.start.substring(0, 10).replaceAll("-", ""),
                avvikelser_ids: avvikelser.map((a) => a.id.trim()).join(", "),
              };
            })
            .filter((event) => event.vecka)
            .filter((event) => event.date <= selectedPeriod.date);

          setFilters(["Alla ordrar", "Alla avvikelser", ...Object.keys(filterMap)]);
          setEvents(items);
        })
        .catch(console.log)
        .finally(() => setLoading(false));
    }
  }, [selectedKund, hsnr, selectedPeriod.date]);

  const eventColors = (event) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  };

  let list = events.map((item) => ({
    ...item,
    date: item.start.substring(0, 10).replaceAll("-", ""),
  }));

  list.sort(function (a, b) {
    return b.date - a.date;
  });

  return (
    <div>
      <MainForm hideDates />
      {alert}

      {selectedKund.kundnr == 0 ? (
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <div style={{ textAlign: "center", paddingTop: 48, paddingBottom: 48 }}>
                <p style={{ color: "#80887f", fontWeight: "bold" }}>Vänligen välj kund</p>
              </div>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        <>
          <GridContainer>
            <GridItem style={{ maxWidth: 215 }}>
              <Button
                color="primary"
                variant="contained"
                style={
                  viewAs === "list"
                    ? { marginRight: 16 }
                    : { backgroundColor: "#fff", color: "#F44435", marginRight: 16 }
                }
                onClick={() => setViewAs("list")}>
                <Menu />
              </Button>

              <Button
                color="primary"
                variant="contained"
                style={viewAs === "calendar" ? {} : { backgroundColor: "#fff", color: "#F44435" }}
                onClick={() => setViewAs("calendar")}>
                <DateRangeIcon />
              </Button>
            </GridItem>
          </GridContainer>

          <div style={{ marginTop: 16 }}>
            {loading ? (
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  <Card>
                    <div style={{ textAlign: "center", paddingTop: 48, paddingBottom: 48 }}>
                      <Logo />
                      <p style={{ color: "#80887f", fontWeight: "bold" }}> Loading...</p>
                    </div>
                  </Card>
                </GridItem>
              </GridContainer>
            ) : (
              <div>
                {viewAs === "calendar" && (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <Card>
                        <BigCalendar
                          selectable
                          localizer={localizer}
                          events={events}
                          defaultView="month"
                          scrollToTime={new Date(1970, 1, 1, 6)}
                          defaultDate={new Date()}
                          onSelectEvent={(event) => selectedEvent(event)}
                          // onSelectSlot={(slotInfo) => addNewEventAlert(slotInfo)}
                          eventPropGetter={eventColors}
                          components={{
                            eventWrapper: ({ event }) => {
                              const [show, setShow] = React.useState(false);
                              return (
                                <div
                                  onClick={() => setShow(!show)}
                                  title={event.title}
                                  className={show ? localClasses.show : localClasses.dot}>
                                  {show ? event.title : ""}
                                </div>
                              );
                            },
                          }}
                        />
                      </Card>
                    </GridItem>
                  </GridContainer>
                )}

                {viewAs === "list" && (
                  <CalendarListView
                    events={list}
                    {...{
                      dates,
                      filters,
                      pastDates,
                      selectedFilter,
                      setSelectedFilter,
                      selectedPeriod,
                      setSelectedPeriod,
                      selectedPast,
                      setSelectedPast,
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
