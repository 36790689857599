// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import "chartjs-plugin-datalabels";
import Card from "components/Card/Card.js";
import React from "react";
import DateRange from "./DateRange";
import ParamsForm from "./ParamsForm";

export default function MainForm({ hideDates, generatePdf, hideButtons, showShortCutIntervals }) {
  const useStyles = makeStyles({
    container: {
      marginTop: "20px",
      marginLeft: "20px",
      marginRight: "20px",
      marginBottom: hideDates ? "20px" : "0px",
    },
  });

  const classes = useStyles();

  return (
    <Card>
      <div className={classes.container}>
        <ParamsForm />
        {!hideDates && <DateRange {...{ generatePdf, hideButtons, showShortCutIntervals }} />}
      </div>
    </Card>
  );
}
