/*export default {
  1: 'Januari',
  2: 'Februari',
  3: 'Mars',
  4: 'April',
  5: 'Maj',
  6: 'Juni',
  7: 'Juli',
  8: 'Augusti',
  9: 'September',
  10: 'Oktober',
  11: 'November',
  12: 'December',
};*/

export default {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "Maj",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Okt",
  11: "Nov",
  12: "Dec",
};
