// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import api from "app/api";
import "chartjs-plugin-datalabels";
import Card from "components/Card/Card.js";
import TagManager from "react-gtm-module";
// core components
import { withStyles } from "@material-ui/core/styles";
import BorderAllIcon from "@material-ui/icons/BorderAll";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import ReactTooltip from "react-tooltip";
import { intervals } from "variables/general";

import { Checkbox, FormControlLabel } from "@material-ui/core";

import { Assignment } from "@material-ui/icons";
import {
  useFromDate,
  useGrupp,
  useHamtstalle,
  useKund,
  useSelectedGraphs,
  useToDate,
} from "app/store";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button.js";
import EmailReportModal from "components/Modal/EmailReportModal";
import MainForm from "./MainForm";
import { Graph, chartProps, graphTypes, pieProps } from "./charts";
import { iconFileToImageSrcMap, nameToIconFileMap } from "./icons";
import { format, generatePdf, getChartData, getMaterialData } from "./pdf";
import useBesparingCO2Korstracka from "./useBesparingCO2Korstracka";
import useBesparingCO2Total from "./useBesparingCO2Total";
import useCo2Material from "./useCo2Material";
import useFordelningAtervinning from "./useFordelningAtervinning";
import useGrafAvfallstyper from "./useGrafAvfallstyper";
import { useSelectedInterval } from "app/store";

const reportPieProps = { ...pieProps, legend: { display: false } };
const reportChartProps = { ...chartProps, legend: { display: false } };

const useStyles = makeStyles(styles);

function createLocalClasses({ selectedInterval }) {
  const localStyles = makeStyles(() => {
    return {
      a4: {
        width: "210mm",
        height: "297mm",
        marginLeft: "auto",
        marginRight: "auto",
      },
      right: {
        textAlign: "right",
      },
      logo: {
        marginTop: "11mm",
        marginRight: "4mm",
      },
      materialIcon: {
        marginLeft: "auto",
        marginRight: "auto",
      },
      pageTitle: {
        paddingLeft: "7mm",
        paddingRight: "7mm",
      },
      graph: {
        marginTop: "9px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "500px",
        height: "312px",
      },
      square: {
        width: "5mm",
        height: "5mm",
        marginTop: "1.5mm",
        marginBottom: "1.5mm",
      },
      table: {
        marginTop: "20mm",
        marginBottom: "30mm",
        marginLeft: "15mm",
      },
      totalSmall: {
        color: "#F44435",
        textAlign: "center",
        fontWeight: "bold",
        margin: 0,
        paddingBottom: 3,
        paddingTop: 5,
        fontSize: "14px",
      },
      total: {
        color: "#F44435",
        textAlign: "center",
        fontWeight: "bold",
        marginTop: "14mm",
        fontSize: "66px",
      },
      grid: {
        position: "absolute",
        width: "210mm",
        height: "297mm",
      },
      td: {
        width: "10mm",
        height: "10mm",
        position: "absolute",
        borderRight: "1px dotted #efefef",
        borderBottom: "1px dotted #efefef",
      },
      amount: {
        fontWeight: "bold",
        textAlign: "right",
        paddingRight: "15mm",
        color: "#515452",
      },
      materialHeaderContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: "0mm",
        marginRight: "2mm",
        marginLeft: "13mm",
        marginBottom: "5mm",
      },
      materialHeaderLeftContainer: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "2mm",
      },
      materialHeaderRightContainer: {
        display: "grid",
        gridTemplateColumns: () =>
          selectedInterval === intervals["12_MONTHS"] ? "repeat(3, 1fr)" : "repeat(3, 1fr)",
        gridTemplateRows: "repeat(2, 1fr)",
        width: "100%",
      },
      gridItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: () => (selectedInterval === intervals["3_MONTHS"] ? "0px" : "0px"),
      },
      materialContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "2rem",
        margin: "0.15rem",
      },
      materialItem: {
        flex: 1,
        display: "flex",
        flexGrow: 1,
        textAlign: "center",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
      },

      buttonsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
      },

      infoWrapper: {
        width: "45%",
        padding: 10,
        borderRadius: 10,
        backgroundColor: "#999",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },

      infoCardsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
        marginBottom: "20px",
      },
    };
  });

  return localStyles();
}

function A4({ children }) {
  const [selectedInterval] = useSelectedInterval();
  const localClasses = createLocalClasses({ selectedInterval });
  let a21 = [];
  for (let i = 0; i < 21; i++) {
    // a21.push(0);
  }

  let a30 = [];
  for (let i = 0; i < 30; i++) {
    // a30.push(0);
  }

  return (
    <Card className={localClasses.a4}>
      {children}
      <div className={localClasses.grid}>
        {a30.map((b, j) =>
          a21.map((a, i) => (
            <div
              key={i}
              style={{ marginTop: j * 10 + "mm", marginLeft: i * 10 + "mm" }}
              className={localClasses.td}>
              {j === 0 ? i : i === 0 ? j : null}
            </div>
          ))
        )}
      </div>
    </Card>
  );
}

function PageTitle({ title }) {
  const [selectedInterval] = useSelectedInterval();
  const localClasses = createLocalClasses({ selectedInterval });

  return (
    <GridContainer className={localClasses.pageTitle}>
      <GridItem xs={9} sm={9} md={9}>
        <h2
          style={{
            color: "#707B71",
            marginTop: "13mm",
            marginLeft: "8mm",
            fontWeight: "bold",
            fontSize: "31px",
          }}>
          {title}
        </h2>
      </GridItem>
      <GridItem xs={3} sm={3} md={3} className={localClasses.right}>
        <img
          src={require("assets/img/esmart-green.png")}
          width="140"
          alt="eSmart"
          className={localClasses.logo}
        />
      </GridItem>
    </GridContainer>
  );
}

function CoverPage() {
  const [toDate] = useToDate();
  const [fromDate] = useFromDate();
  const [selectedKund] = useKund();
  const [selectedHamtstalle] = useHamtstalle();

  const h = {
    textAlign: "center",
    color: "#707B71",
    fontWeight: "bold",
    marginBottom: "0",
  };

  const h1Style = { ...h, fontSize: "55px" };
  const h2Style = { ...h, fontSize: "42px" };
  const h3Style = { ...h, fontSize: "30px" };

  return (
    <A4>
      <h1 style={{ ...h1Style, marginTop: "77mm" }}>Återvinnings-</h1>
      <h1 style={{ ...h1Style, marginTop: "-4px" }}>och miljörapport</h1>

      <h2 style={{ ...h2Style, marginTop: "32px" }}>{(selectedKund || {}).namn}</h2>
      <h3 style={{ ...h3Style, marginTop: "28px" }}>{"Period " + fromDate + " till " + toDate}</h3>
      <h2 style={{ ...h2Style, marginTop: "26px" }}>{(selectedHamtstalle || {}).location}</h2>

      <div
        style={{
          height: "50mm",
          marginTop: "72mm",
          textAlign: "center",
          backgroundColor: "#77817B",
        }}>
        <img
          width="270"
          alt="eSmart"
          style={{ marginTop: "8mm" }}
          src={require("assets/img/esmart.png")}
        />
      </div>
    </A4>
  );
}

function GraphPage({ title, chart, data, pie }) {
  const [selectedInterval] = useSelectedInterval();
  const localClasses = createLocalClasses({ selectedInterval });

  let chartData = { total: 0, items: [] };
  if (data) {
    chartData = getChartData(data, pie);
  }

  return (
    <A4>
      <PageTitle title={title} />

      <h1 className={localClasses.total}>{chartData.total} kg</h1>

      <div className={localClasses.graph}>{chart}</div>

      <table className={localClasses.table}>
        <tbody>
          {chartData.items
            .map((item, i) => {
              if (i % 2 === 0) {
                const right = i + 1 < chartData.items.length ? chartData.items[i + 1] : {};
                return (
                  <tr key={i}>
                    <td>
                      <div
                        style={{ backgroundColor: item.color }}
                        className={localClasses.square}></div>
                    </td>
                    <td>{item.text}</td> <td className={localClasses.amount}>{item.amount} kg</td>
                    <td>
                      {right.text ? (
                        <div
                          style={{ backgroundColor: right.color }}
                          className={localClasses.square}></div>
                      ) : null}
                    </td>
                    <td>{right.text}</td>
                    <td className={localClasses.amount}>
                      {right.amount} {right.text ? "kg" : null}
                    </td>
                  </tr>
                );
              }

              return null;
            })
            .filter((v) => v)}
        </tbody>
      </table>
    </A4>
  );
}

function EquivalentPage({ data }) {
  const [selectedInterval] = useSelectedInterval();
  const localClasses = createLocalClasses({ selectedInterval });

  if (!data) {
    return null;
  }

  const chartData = getChartData(data);

  // 1 kg of saved co2 = 7 Mils tågkörning

  // 1 kg of saved co2 = 0,4 mils resa med bil

  // 1 kg of saved co2 = 3 Timmars dammsugning

  const items = [
    {
      src: require("assets/img/materials/train.png"),
      amount: format(7 * chartData.total),
      text: "mils tågresande",
    },
    {
      src: require("assets/img/materials/car.png"),
      amount: format(0.4 * chartData.total),
      text: "mils bilkörning",
    },
    {
      src: require("assets/img/materials/vacuum.png"),
      amount: format(3 * chartData.total),
      text: "timmars dammsugning med grön el",
    },
  ];
  return (
    <A4>
      <PageTitle title={"Total besparing CO2 motsvarande"} />

      <h1 className={localClasses.total} style={{ marginBottom: "34px" }}>
        {chartData.total} kg CO2
      </h1>

      <table className={localClasses.table}>
        <tbody>
          {items.map((item, key) => (
            <tr key={key}>
              <td style={{ paddingLeft: "23mm" }}>
                <img src={item.src} width="160" style={{ marginBottom: "8mm" }} alt={item.text} />
              </td>
              <td style={{ paddingRight: "20mm" }}>
                <h1
                  style={{
                    color: "#F44435",
                    fontWeight: "bold",
                    paddingLeft: "15mm",
                    fontSize: "60px",
                    margin: "0",
                  }}>
                  {item.amount}
                </h1>
                <h2
                  style={{
                    color: "#707B71",
                    marginTop: "1mm",
                    marginBottom: "1mm",
                    marginLeft: "14mm",
                    fontWeight: "bold",
                    fontSize: "31px",
                  }}>
                  {item.text}
                </h2>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </A4>
  );
}

function EquivalentCard({ data, classes, hideTotalSaveCorrespondingCo2 }) {
  const { selectedGraphs } = useSelectedGraphs();

  if (!data) {
    return null;
  }

  const chartData = getChartData(data);

  // 1 kg of saved co2 = 7 Mils tågkörning

  // 1 kg of saved co2 = 0,4 mils resa med bil

  // 1 kg of saved co2 = 3 Timmars dammsugning

  const items = [
    {
      src: require("assets/img/materials/train.png"),
      amount: format(7 * chartData.total),
      text: "mils tågresande",
    },

    {
      src: require("assets/img/materials/vacuum.png"),
      amount: format(3 * chartData.total),
      text: "timmars dammsugning med grön el",
    },
    {
      src: require("assets/img/materials/car.png"),
      amount: format(0.4 * chartData.total),
      text: "mils bilkörning",
    },
  ];
  return (
    <Graph
      title="Den totala besparingen av CO2 under valt period motsvarar"
      {...{ classes }}
      id="7"
      isSelected={selectedGraphs.includes("7")}
      useSelect={true}
      type={graphTypes.small}>
      {!hideTotalSaveCorrespondingCo2 && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            alignItems: "center",
            alignContent: "center",
          }}>
          {items.map((item, key) => (
            <div style={{ flex: 1, margin: "2px", textAlign: "center" }} key={key}>
              <img src={item.src} width="50" alt="" />
              <p
                style={{
                  color: "#F44435",
                  fontWeight: "bold",
                  paddingTop: "8px",
                  fontSize: "16px",
                  margin: "0",
                }}>
                {item.amount}
              </p>
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      )}
    </Graph>
  );
}

/* 
Build Material Pages
*/

function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

function MaterialPage({ grafAvfallstyper, selectedInterval }) {
  const localClasses = createLocalClasses({ selectedInterval });

  let materials = getMaterialData(grafAvfallstyper);

  if (selectedInterval === intervals["3_MONTHS"] || selectedInterval === intervals["6_MONTHS"]) {
    materials = sliceIntoChunks(materials, 5);
  }

  if (selectedInterval === intervals["12_MONTHS"]) {
    materials = sliceIntoChunks(materials, 4);
  }

  function renderMaterials(materialArray) {
    return (
      <A4>
        <PageTitle title={"Material"} />

        <div>
          {materialArray.map((data, key) => {
            const iconFile = nameToIconFileMap[data.type] || "other.png";

            const src = iconFileToImageSrcMap[iconFile];
            return (
              <div key={key} className={localClasses.materialHeaderContainer}>
                <div className={localClasses.materialHeaderLeftContainer}>
                  <img src={src} height="30" width="30" className={localClasses.materialIcon} />
                  <div
                    style={{
                      marginLeft: "1.5mm",
                      marginTop: "2.8mm",
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "flex-start",
                      alignItems: "flex-start",
                    }}>
                    <p style={{ color: "#78817B", fontWeight: "bold", padding: 0, margin: 0 }}>
                      {data.type}
                    </p>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <p style={{ color: "#78817B", fontWeight: "bold", padding: 0, margin: 0 }}>
                        Totalt:{"  "}
                      </p>
                      <p
                        style={{
                          marginLeft: "2mm",
                          fontWeight: "bold",
                          color: "#F44435",
                          padding: 0,
                        }}>
                        {data.total} kg
                      </p>
                    </div>
                  </div>
                </div>

                {/* 
                style={{ display: 'flex', flexDirection: 'row' } */}
                <div className={localClasses.materialHeaderRightContainer}>
                  {(data.items || [])
                    .map((item, i) => {
                      return (
                        <div className={localClasses.gridItem} key={i}>
                          <p>{item.text}</p>
                          <p className={localClasses.amount}>{item.amount} kg</p>
                        </div>
                      );
                    })
                    .filter((v) => v)}
                </div>
              </div>
            );
          })}
        </div>
      </A4>
    );
  }

  if (Array.isArray(materials[0])) {
    return (
      <div>
        {materials.map((materialArray, key) => {
          return <div key={key}>{renderMaterials(materialArray)}</div>;
        })}
      </div>
    );
  }

  return renderMaterials(materials);
}

const checkBoxStyles = () => ({
  root: {
    "&$checked": {
      color: "#F44435",
    },
  },
  checked: {},
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

/* 
REPORT PAGE
*/

export default function Report() {
  const classes = useStyles();
  const [selectedInterval] = useSelectedInterval();

  const ref1 = React.useRef();
  const ref2 = React.useRef();
  const ref3 = React.useRef();
  const ref4 = React.useRef();
  const ref5 = React.useRef();
  const ref6 = React.useRef();

  const { selectedGraphs, setSelectedGraphs } = useSelectedGraphs();

  const [hideTotalSaveCorrespondingCo2] = React.useState(false);
  const [allChecked, setAllChecked] = React.useState(false);
  const [openEmailModal, setOpenEmailModal] = React.useState(false);
  const [shouldSendExcel, setShouldSendExcel] = React.useState(false);

  const besparingCO2Korstracka = useBesparingCO2Korstracka();
  const besparingCO2Total = useBesparingCO2Total();
  const { co2Material, co2MaterialPerMonth } = useCo2Material();
  const fordelningAtervinning = useFordelningAtervinning();
  const grafAvfallstyper = useGrafAvfallstyper();

  const [toDate] = useToDate();
  const [fromDate] = useFromDate();
  const [selectedKund] = useKund();
  const [selectedGrupp] = useGrupp();

  const [selectedHamtstalle] = useHamtstalle();

  const hsnr = (selectedHamtstalle || { id: 0 }).id;

  function downloadCsv() {
    TagManager.dataLayer({
      dataLayer: {
        event: "excelStats",
      },
    });
    api.Reports.Detailed(selectedKund.kundnr, fromDate, toDate, hsnr, selectedGrupp)
      .then((url) => {
        // window.open(url);
        console.log({ url });
        const anchor = document.createElement("a");
        anchor.href = url.replace("http:", "https:");
        // anchor.download = 'report.csv';
        // Set the desired file name
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      })
      .catch(console.log);

    console.log("downloadCsv...");
  }

  async function handleSendEmail(emails) {
    let success;
    if (shouldSendExcel) {
      const url = await api.Reports.Detailed(
        selectedKund.kundnr,
        fromDate,
        toDate,
        hsnr,
        selectedGrupp
      );
      const response = await api.sendEmail(
        emails,
        null,
        "Statistik.xlsx",
        "Statistik från eSmart",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        url,
        "Här är statistiken från eSmart. Detta är ett automatiskt genererat mail. Detta mail är inte tänkt att besvaras."
      );
      setShouldSendExcel(false);
      if (response.status === 200) {
        success = true;
      } else {
        success = false;
      }
    } else {
      success = await generatePdf(
        {
          ref1,
          ref2,
          ref3,
          ref4,
          ref5,
          ref6,
          hamtstalle: selectedHamtstalle,
          fromDate,
          toDate,
          name: selectedKund.namn,
          materials: getMaterialData(grafAvfallstyper),
        },
        selectedGraphs,
        selectedInterval,
        emails
      );
    }
    return success;
  }

  function renderGraphCards({ selectedInterval }) {
    const localClasses = createLocalClasses({ selectedInterval });

    const handleCheckAll = (e) => {
      const { checked } = e.target;
      setAllChecked(checked);

      setSelectedGraphs(checked ? ["1", "2", "3", "4", "5", "6", "7", "8"] : []);
    };

    return (
      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <Assignment />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <GridContainer style={{ padding: "10px" }}>
            <GridItem xs={12} sm={12} md={12}>
              <h3>Miljörapporter</h3>
              <div className={localClasses.infoCardsContainer}>
                <div className={localClasses.infoWrapper}>
                  <div>
                    <h4>PDF Rapport</h4>
                    <ul>
                      <li>
                        <p> Välj den perioden du önskar att rapporten ska mortsvara.</p>
                      </li>
                      <li>
                        <p> Bocka i fälten nedan för de rapporter du önskar ha med i din PDF.</p>
                      </li>
                      <li>
                        <p>
                          Tryck sedan på knappen ”Skapa PDF Rapport” för att ladda ner filen, eller
                          ”Email PDF rapport” för mejla rapporten till vald mailadress.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className={localClasses.buttonsContainer}>
                    <Button
                      color="primary"
                      onClick={() =>
                        generatePdf(
                          {
                            ref1,
                            ref2,
                            ref3,
                            ref4,
                            ref5,
                            ref6,
                            hamtstalle: selectedHamtstalle,
                            fromDate,
                            toDate,
                            name: selectedKund.namn,
                            materials: getMaterialData(grafAvfallstyper),
                          },
                          selectedGraphs,
                          selectedInterval,
                          null
                        )
                      }
                      className={classes.button}>
                      <PictureAsPdfIcon /> Skapa PDF Rapport
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => setOpenEmailModal(true)}
                      className={classes.button}>
                      <PictureAsPdfIcon /> Email PDF Rapport
                    </Button>
                  </div>
                </div>
                <div className={localClasses.infoWrapper}>
                  <div>
                    <h4>Excel Rapport</h4>
                    <ul>
                      <li>
                        <p> Välj den perioden du önskar att rapporten ska mortsvara.</p>
                      </li>
                      <li>
                        <p>
                          Tryck sedan på knappen ”Skapa Excel-statistik” för att ladda ner filen,
                          eller ”Email Excel-statistik” för mejla rapporten till vald mailadress.
                        </p>
                      </li>
                    </ul>
                    {/* <p>
                    Ovanför varje rapport finns en informationsbox som förklarar vad statistiken
                    avser i detalj.
                  </p> */}
                  </div>
                  <div className={localClasses.buttonsContainer}>
                    <Button onClick={downloadCsv} className={classes.button} color="primary">
                      <BorderAllIcon /> Skapa Excel-statistik
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        setOpenEmailModal(true);
                        setShouldSendExcel(true);
                      }}
                      className={classes.button}>
                      <BorderAllIcon /> Email Excel-Statistik
                    </Button>
                  </div>
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h3>Statistikfält</h3>
              {/* <div>
            <input type="checkbox" id="selectall" name="selectall" />
            <label for="selectall">Välj alla</label>
          </div> */}
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={allChecked}
                    onClick={handleCheckAll}
                    // onClick={() => store.addToSelectedGraphs(id)}
                    // {...label}
                  />
                }
                color="black"
                label={allChecked ? "Avmarkera alla" : "Markera alla"}
                style={{ color: "#3c4858" }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Graph
                title="Hämtat material per materialslag och kilo"
                {...{ classes }}
                id="1"
                tooltipId="info1"
                isSelected={selectedGraphs.includes("1")}
                useSelect={true}
                type={graphTypes.small}
                tooltip={
                  <ReactTooltip id="info1">
                    <div style={{ maxWidth: 400 }}>
                      <strong>Vad grafen redovisar</strong>
                      <p>
                        Grafen redovisar hur mycket material vi har hämtat. Materialet är uppdelat
                        per materialslag och fördelning per månad.
                      </p>
                      <strong>Så här har vi räknat</strong>

                      <p>
                        En del material är beräknat på schablonvärde vilket är vanligt förekommande
                        vid viktberäkning. Det är en snittvikt för tusentals hämtade kärl från
                        samtliga kunder där vi på så sätt kan räkna ut vikt för både material och
                        kärl. Denna metod används i hela branschen då det inte finns möjlighet att
                        väga kärl, säckar eller behållare separat. Undantaget är vägning av farligt
                        avfall som sker enligt lagstiftning.
                      </p>
                    </div>
                  </ReactTooltip>
                }>
                {grafAvfallstyper != null && grafAvfallstyper != undefined && (
                  <Line ref={ref1} {...chartProps} data={grafAvfallstyper} />
                )}
              </Graph>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Graph
                title="Fördelning hämtat material per kategori och i Kilo"
                {...{ classes }}
                id="2"
                tooltipId="info2"
                useSelect={true}
                isSelected={selectedGraphs.includes("2")}
                type={graphTypes.small}
                tooltip={
                  <ReactTooltip id="info2">
                    <div style={{ maxWidth: 400 }}>
                      <strong>Vad grafen redovisar</strong>
                      <p>
                        Grafen redovisar vad som sker med det material vi hämtat. Det är en
                        fördelning av vad som går till energi- eller materialåtervinning, vad som är
                        farligt avfall, vad som återbrukas samt vad som läggs på deponi.
                      </p>
                      <strong>Så här har vi räknat</strong>
                      <p>
                        Allt material som vi hämtat går till en av de nämnda processerna ovan. Här
                        redovisas fördelningen av det material som vi hämtat specifikt från er.
                      </p>
                    </div>
                  </ReactTooltip>
                }>
                {fordelningAtervinning && (
                  <Doughnut ref={ref2} data={fordelningAtervinning} {...pieProps} />
                )}
              </Graph>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Graph
                title="Besparat kilo CO2 per materialslag genom återvinning framför nyproduktion."
                {...{ classes }}
                id="3"
                tooltipId="info3"
                isSelected={selectedGraphs.includes("3")}
                useSelect={true}
                type={graphTypes.small}
                tooltip={
                  <ReactTooltip id="info3">
                    <div style={{ maxWidth: 400 }}>
                      <strong>Vad grafen redovisar</strong>
                      <p>
                        Grafen redovisar besparat CO2-utsläpp per materialslag från er verksamhets
                        återvinning jämfört med om ni inte hade sorterat, återvunnit eller
                        återbrukat. Det är besparingen av CO2 som utvinns genom att processa
                        material i stället för att producera nytt råmaterial av samma materialslag.
                      </p>
                      <strong>Så här har vi räknat</strong>
                      <p>
                        Beräkningarna baseras på besparat CO2-utsläpp genom materialåtervinning
                        eller återbruk jämfört med nyproduktion på råmaterial. Siffrorna redovisas
                        utifrån materialnivå och kommer från Återvinningsindustrierna samt IVL
                        Svenska Miljöinstitutet.
                      </p>
                    </div>
                  </ReactTooltip>
                }>
                {co2Material && <Bar ref={ref3} data={co2Material} {...chartProps} />}
              </Graph>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Graph
                title="Besparat kilo CO2 per månad genom återvinning"
                {...{ classes }}
                id="4"
                tooltipId="info4"
                isSelected={selectedGraphs.includes("4")}
                useSelect={true}
                type={graphTypes.small}
                tooltip={
                  <ReactTooltip id="info4">
                    <div style={{ maxWidth: 400 }}>
                      <strong>Vad grafen redovisar</strong>
                      <p>
                        Grafen redovisar besparat CO2-utsläpp per månad från er verksamhets
                        återvinning jämfört med om ni inte hade sorterat, återvunnit eller
                        återbrukat. Det är besparingen av CO2 som utvinns genom att processa
                        material i stället för att producera nytt råmaterial av samma materialslag.
                      </p>
                      <strong>Så här har vi räknat</strong>
                      <p>
                        Beräkningarna baseras på besparat CO2-utsläpp genom materialåtervinning
                        eller återbruk jämfört med nyproduktion på råmaterial. Siffrorna redovisas
                        utifrån materialnivå och kommer från Återvinningsindustrierna samt IVL
                        Svenska Miljöinstitutet.
                      </p>
                    </div>
                  </ReactTooltip>
                }>
                {co2MaterialPerMonth && (
                  <Bar ref={ref4} data={co2MaterialPerMonth} {...chartProps} />
                )}
              </Graph>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Graph
                title="Besparat Kilo CO2 genom fossilfria transport istället för en Dieseldriven motsvarighet"
                {...{ classes }}
                id="5"
                isSelected={selectedGraphs.includes("5")}
                tooltipId="info5"
                useSelect={true}
                type={graphTypes.small}
                tooltip={
                  <ReactTooltip id="info5">
                    <div style={{ maxWidth: 400 }}>
                      <strong>Vad grafen redovisar</strong>
                      <p>
                        Grafen redovisar besparat CO2-utsläpp genom valet av oss som fossilfri
                        leverantör i stället för en dieselberoende leverantör. Siffrorna jämförs
                        utifrån distansen mellan vår anläggning och era hämtningsplatser med ett
                        likadant fordon som kör på diesel.
                      </p>
                      <strong>Så här har vi räknat</strong>
                      <p>
                        Beräkningarna baseras på besparat CO2-utsläpp per körd kilometer, mellan
                        fordon körda på 100 procent biogas jämfört med diesel. Vi beräknar utsläppen
                        av vår egen biogas-lastbil och jämför siffrorna med en likvärdig
                        diesel-lastbil. Siffrorna är hämtade från Eon, IVL Svenska Miljöinstitutet
                        samt IVECO.
                      </p>
                    </div>
                  </ReactTooltip>
                }>
                {besparingCO2Korstracka && (
                  <Bar ref={ref5} data={besparingCO2Korstracka} {...chartProps} />
                )}
              </Graph>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Graph
                title="Total Besparat Co2 per månad (återvinning och transporter)"
                {...{ classes }}
                id="6"
                tooltipId="info6"
                isSelected={selectedGraphs.includes("6")}
                useSelect={true}
                type={graphTypes.small}
                tooltip={
                  <ReactTooltip id="info6">
                    <div style={{ maxWidth: 400 }}>
                      <strong>Vad grafen redovisar</strong>
                      <p>
                        Grafen redovisar besparat CO2-utsläpp per månad genom materialåtervinning
                        eller återbruk jämfört med nyproduktion på råmaterial samt valet av oss som
                        fossilfri leverantör i stället för en dieselberoende leverantör.
                      </p>
                      <strong>Så här har vi räknat</strong>
                      <p>
                        Beräkningarna baseras på besparat CO2-utsläpp genom materialåtervinning
                        eller återbruk jämfört med nyproduktion på råmaterial. Siffrorna redovisas
                        utifrån materialnivå och kommer från Återvinningsindustrierna samt IVL
                        Svenska Miljöinstitutet. Dessa siffror adderas till beräkningarna baserade
                        på besparat CO2-utsläpp per körd kilometer, mellan fordon körda på 100
                        procent biogas jämfört med diesel. Vi beräknar utsläppen av vår egen
                        biogas-lastbil och jämför siffrorna med en likvärdig diesel-lastbil.
                        Siffrorna är hämtade från Eon, IVL Svenska Miljöinstitutet samt IVECO.
                      </p>
                    </div>
                  </ReactTooltip>
                }>
                {besparingCO2Total && <Bar ref={ref6} data={besparingCO2Total} {...chartProps} />}
              </Graph>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <EquivalentCard
                data={besparingCO2Total}
                classes={classes}
                hideTotalSaveCorrespondingCo2={hideTotalSaveCorrespondingCo2}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <Graph
                title="Mängd material per månad och materialslag under valt period"
                {...{ classes }}
                id="8"
                tooltipId="info8"
                isSelected={selectedGraphs.includes("8")}
                useSelect={true}
                type={graphTypes.small}
                tooltip={
                  <ReactTooltip id="info8">
                    <div style={{ maxWidth: 400 }}>
                      <strong>Vad grafen redovisar</strong>
                      <p>
                        Grafen redovisar vilka materialslag vi har hämtat från er under valt period.
                        I rapportform redovisas dom olika materialslagen uppdelat per kalendermånad
                        och en total för perioden.
                      </p>
                    </div>
                  </ReactTooltip>
                }>
                <div className={localClasses.materialContainer}>
                  {grafAvfallstyper != null ? (
                    getMaterialData(grafAvfallstyper)
                      .slice(0, 7)
                      .map((item, index) => {
                        const iconFile = nameToIconFileMap[item.type] || "other.png";

                        const src = iconFileToImageSrcMap[iconFile];

                        return (
                          <div key={index} className={localClasses.materialItem}>
                            <img src={src} width="50" alt="" />

                            <p
                              style={{
                                color: "#F44435",
                                fontWeight: "bold",
                                paddingTop: "8px",
                                fontSize: "16px",
                                marginBottom: "20px",
                              }}>
                              {item.total} kg
                            </p>

                            <p>{item.type}</p>
                          </div>
                        );
                      })
                  ) : (
                    <></>
                  )}

                  {getMaterialData(grafAvfallstyper).length > 7 ? (
                    <p> + {getMaterialData(grafAvfallstyper).length - 7} material... </p>
                  ) : (
                    <></>
                  )}
                </div>
              </Graph>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }

  function renderPdfPreview({ selectedInterval }) {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={5}>
          <CoverPage />

          {selectedGraphs.includes("1") && (
            <GraphPage
              data={grafAvfallstyper}
              title="Hämtat material i kilo"
              chart={
                grafAvfallstyper && (
                  <Line ref={ref1} {...reportChartProps} data={grafAvfallstyper} />
                )
              }
            />
          )}

          {selectedGraphs.includes("2") && (
            <GraphPage
              pie
              data={fordelningAtervinning}
              title="Fördelning hämtat material"
              chart={
                fordelningAtervinning && (
                  <Doughnut ref={ref2} data={fordelningAtervinning} {...reportPieProps} />
                )
              }
            />
          )}

          {selectedGraphs.includes("3") && (
            <GraphPage
              data={co2Material}
              title="Besparat kilo CO2 per materialslag genom återvinning"
              chart={co2Material && <Bar ref={ref3} data={co2Material} {...reportChartProps} />}
            />
          )}

          {selectedGraphs.includes("4") && (
            <GraphPage
              data={co2MaterialPerMonth}
              title="Besparat kilo CO2 per månad genom återvinning"
              chart={
                co2MaterialPerMonth && (
                  <Bar ref={ref4} data={co2MaterialPerMonth} {...reportChartProps} />
                )
              }
            />
          )}

          {selectedGraphs.includes("5") && (
            <GraphPage
              data={co2MaterialPerMonth}
              title="Besparat kilo CO2 genom fossilfria transporter"
              chart={
                co2MaterialPerMonth && (
                  <Bar ref={ref4} data={co2MaterialPerMonth} {...reportChartProps} />
                )
              }
            />
          )}

          {selectedGraphs.includes("6") && (
            <GraphPage
              data={besparingCO2Total}
              title="Total besparat co2 per månad"
              chart={
                besparingCO2Total && (
                  <Bar ref={ref6} data={besparingCO2Total} {...reportChartProps} />
                )
              }
            />
          )}

          {selectedGraphs.includes("7") && <EquivalentPage data={besparingCO2Total} />}

          {selectedGraphs.includes("8") && (
            <MaterialPage {...{ selectedInterval, grafAvfallstyper }} />
          )}
        </GridItem>
      </GridContainer>
    );
  }
  return (
    <div>
      <MainForm
        showShortCutIntervals
        generatePdf={(email) => {
          return generatePdf(
            {
              ref1,
              ref2,
              ref3,
              ref4,
              ref5,
              ref6,
              hamtstalle: selectedHamtstalle,
              toDate,
              fromDate,
              name: selectedKund.namn,
              materials: getMaterialData(grafAvfallstyper),
            },
            selectedGraphs,
            selectedInterval,
            email
          );
        }}
      />
      <br />
      {renderGraphCards({ selectedInterval })}
      {renderPdfPreview({ selectedInterval })}
      <br />
      <EmailReportModal
        show={openEmailModal}
        onClose={() => setOpenEmailModal(false)}
        handleSend={(emails) => handleSendEmail(emails)}
      />
    </div>
  );
}
