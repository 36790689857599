// @material-ui/core components
import "chartjs-plugin-datalabels";
import Button from "components/CustomButtons/Button.js";
import TagManager from "react-gtm-module";
// core components
import { makeStyles } from "@material-ui/core/styles";
import BorderAllIcon from "@material-ui/icons/BorderAll";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import api from "app/api";
import { useFromDate, useGrupp, useHamtstalle, useKund, useToDate } from "app/store";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import DateRangeIcon from "@material-ui/icons/DateRange";

import { intervals } from "variables/general";
import { useSelectedInterval } from "app/store";

const useStyles = makeStyles((theme) => {
  return {
    buttonsContainer: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "20px",
      },
    },
    container: {
      marginTop: "20px",
    },
    tools: {
      paddingBottom: "10px",
      textAlign: "right",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
      [theme.breakpoints.down("m")]: {
        textAlign: "left",
      },
    },
    intervalButtons: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
  };
});

export default function DateRange({ generatePdf, showShortCutIntervals }) {
  const classes = useStyles();
  const aRef = React.useRef(null);
  const [selectedGrupp] = useGrupp();
  const [toDate, setToDate] = useToDate();
  const [, setHref] = React.useState(null);
  const [selectedHamtstalle] = useHamtstalle();
  const [fromDate, setFromDate] = useFromDate();
  const [selectedKund] = useKund();

  const [, setSelectedInterval] = useSelectedInterval();

  const [, setDownloading] = React.useState(false);

  const hsnr = (selectedHamtstalle || { id: 0 }).id;

  function shortCutIntervals(interval) {
    if (interval === intervals["3_MONTHS"]) {
      TagManager.dataLayer({
        dataLayer: {
          event: "threeMClick",
        },
      });
      setDate(3);
      setSelectedInterval(intervals["3_MONTHS"]);
    } else if (interval === intervals["6_MONTHS"]) {
      TagManager.dataLayer({
        dataLayer: {
          event: "sixMClick",
        },
      });
      setDate(6);
      setSelectedInterval(intervals["6_MONTHS"]);
    } else if (interval === intervals["12_MONTHS"]) {
      TagManager.dataLayer({
        dataLayer: {
          event: "twelveMClick",
        },
      });
      setDate(12);
      setSelectedInterval(intervals["12_MONTHS"]);
    }
  }

  function setDate(interval) {
    const date = new Date();

    setToDate(date.toISOString().substring(0, 10));
    date.setMonth(date.getMonth() - interval);
    setFromDate(date.toISOString().substring(0, 10));

    console.log(date.toISOString().substring(0, 10));
  }

  function downloadCsv() {
    setDownloading(true);
    api.Reports.Detailed(selectedKund?.kundnr, fromDate, toDate, hsnr, selectedGrupp)
      .then((url) => {
        // window.open(url);
        setHref(url.replace("http:", "https:"));
        setTimeout(() => aRef.current.click(), 10);
      })
      .catch(console.log)
      .finally(() => setDownloading(false));
  }

  return (
    <div className={classes.container}>
      <h5>Välj tidsintervall</h5>
      <GridContainer className={classes.tools}>
        <GridItem xs={12} sm={12} md={3} lg={2}>
          <CustomInput
            labelText="Från"
            id="from"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "date",
              value: fromDate,
              InputLabelProps: {
                shrink: true,
              },
              onChange: (event) => setFromDate(event.target.value),
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={3} lg={2}>
          <CustomInput
            labelText="Till"
            id="to"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "date",
              value: toDate,
              InputLabelProps: {
                shrink: true,
              },
              onChange: (event) => setToDate(event.target.value),
            }}
          />
        </GridItem>

        {false && (
          <GridItem xs={12} sm={12} md={3} lg={4} className={classes.tools}>
            <Button color="primary" onClick={generatePdf} loading className={classes.button}>
              <PictureAsPdfIcon /> Pdf report
            </Button>

            <Button color="primary" onClick={downloadCsv} loading className={classes.button}>
              <BorderAllIcon /> Excel-statistik
            </Button>
          </GridItem>
        )}

        {showShortCutIntervals && (
          <GridItem xs={12} sm={12} md={6} lg={8} className={classes.buttonsContainer}>
            <Button color="primary" onClick={() => shortCutIntervals(intervals["3_MONTHS"])}>
              <DateRangeIcon /> 3 månader
            </Button>

            <Button color="primary" onClick={() => shortCutIntervals(intervals["6_MONTHS"])}>
              <DateRangeIcon /> 6 månader
            </Button>

            <Button color="primary" onClick={() => shortCutIntervals(intervals["12_MONTHS"])}>
              <DateRangeIcon /> 12 månader
            </Button>
          </GridItem>
        )}
      </GridContainer>
    </div>
  );
}
