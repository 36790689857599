import TagManager from "react-gtm-module";
import esmart from "assets/img/esmart";
import esmartGreen from "assets/img/esmart-green";
import car_png from "assets/img/materials/car_png";
import other_png from "assets/img/materials/other_png";
import train_png from "assets/img/materials/train_png";
import vacuum_png from "assets/img/materials/vacuum_png";
import { intervals } from "variables/general";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import api from "app/api";
import { nameToIconBase64Map } from "./icons";
import months from "./months";
const a4 = { width: 210, height: 297 };

export function format(num) {
  return (Math.round((num + Number.EPSILON) * 10) / 10).toFixed(1);
}

function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

export async function generatePdf(data, selectedGraphs, timeInterval, emails) {
  // a4 = 210 × 297 mm
  TagManager.dataLayer({
    dataLayer: {
      event: "createPdf",
    },
  });

  const doc = new jsPDF("p", "mm", "a4", true);

  coverPage(doc, data);

  const pages = [
    {
      id: "1",
      title: "Hämtat material per materialslag och kilo",
      ref: data.ref1,
      newTitle: "Hämtat material per materialslag och kilo",
    },
    {
      id: "2",
      title: "Fördelning hämtat material per",
      title2: "kategori och i Kilo",
      ref: data.ref2,
      pie: true,
      newTitle: "Fördelning hämtat material per kategori och i Kilo",
    },
    {
      id: "3",
      title: "Besparat kilo CO2 per materialslag genom ",
      title2: "återvinning framför nyproduktion.",
      ref: data.ref3,
      newTitle: "Besparat kilo CO2 per materialslag genom återvinning framför nyproduktion.",
    },
    {
      id: "4",
      title: "Besparat kilo CO2 per månad genom ",
      title2: "återvinning",
      ref: data.ref4,
      newTitle: "Besparat kilo CO2 per månad genom återvinning",
    },
    {
      id: "5",
      title: "Besparat Kilo CO2 genom fossilfria",
      title2: "transport istället för en Dieseldriven",
      title3: "motsvarighet",
      ref: data.ref5,
      newTitle:
        "Besparat Kilo CO2 genom fossilfria transport istället för en Dieseldriven motsvarighet",
    },
    {
      id: "6",
      title: "Total besparat co2 per månad",
      title2: "(återvinning och transporter)",
      ref: data.ref6,
      newTitle: "Total Besparat Co2 per månad (återvinning och transporter)",
    },
  ];

  const sendGraphEvent = (title) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "graphInPDF",
        graphTitle: title,
      },
    });
  };

  selectedGraphs.forEach((graph) => {
    let title = "Ingen titel på graph";
    if (graph === "7") {
      title = "Den totala besparingen av CO2 under valt period motsvarar";
    }
    if (graph === "8") {
      title = "Mängd material per månad och materialslag under valt period";
    }
    if (pages.find((page) => page.id === graph)) {
      title = pages.find((page) => page.id === graph).newTitle;
    }
    sendGraphEvent(title);
  });

  const selectedPages = pages.filter((page) => selectedGraphs.includes(page.id));

  selectedPages.forEach((page) => {
    doc.addPage();
    graphPage(doc, page);
  });

  if (selectedGraphs.includes("7")) {
    doc.addPage();
    equivalentPage(doc, {
      title: "Den totala besparingen av CO2 under valt",
      title2: "period motsvarar",
      ref: data.ref6,
    });
  }

  if (selectedGraphs.includes("8")) {
    if (timeInterval === intervals["3_MONTHS"] || timeInterval === intervals["6_MONTHS"]) {
      let slicedMaterials = sliceIntoChunks(data.materials, 6);

      slicedMaterials.forEach((materials, index) => {
        doc.addPage();

        materials.forEach((material, i) => {
          materialPage(doc, material, i, timeInterval);
        });
      });
    }

    if (timeInterval === intervals["12_MONTHS"]) {
      let slicedMaterials = sliceIntoChunks(data.materials, 4);

      slicedMaterials.forEach((materials, index) => {
        doc.addPage();

        materials.forEach((material, i) => {
          materialPage(doc, material, i, timeInterval);
        });
      });
    }
  }

  if (emails) {
    let res = await api.sendEmail(
      emails,
      doc.output("datauristring").split(",")[1],
      "Rapport och statistik.pdf",
      "Rapport från eSmart",
      null,
      null,
      "Här är din rapport från eSmart. Detta är ett automatiskt genererat mail. Detta mail är inte tänkt att besvaras."
    );

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  } else {
    doc.save("Rapportering-och-statistik.pdf");
    return true;
  }
}

function coverPage(doc, data) {
  doc.setTextColor("#77817B");
  doc.setFillColor("#77817B");

  // doc.rect(0, 0, 210, 80, 'F');

  doc.setFontSize(40);
  doc.setFont("helvetica", "bold");
  doc.textCenter("Återvinnings-", { align: "center" }, 0, 90);
  doc.textCenter("och miljörapport", { align: "center" }, 0, 105);

  doc.setFontSize(30);
  doc.setFont("helvetica", "bold");
  doc.textCenter(data.name, { align: "center" }, 0, 130);

  doc.setFontSize(20);
  doc.setFont("helvetica", "bold");
  doc.textCenter("Period " + data.fromDate + " till " + data.toDate, { align: "center" }, 0, 150);

  doc.setFontSize(24);
  doc.setFont("helvetica", "bold");
  doc.textCenter(data.hamtstalle?.location || "", { align: "center" }, 0, 170);

  doc.rect(0, 247, 210, 50, "F");
  doc.addImage(esmart, "PNG", 70, 257, 70, 24);

  // grid(doc);
}

function graphPage(doc, page) {
  doc.setTextColor("#77817B");
  doc.setFillColor("#77817B");
  const factor = 20;
  doc.addImage(esmartGreen, "PNG", a4.width - 820 / factor - 10, 10, 820 / factor, 390 / factor);

  pageTitle(page.title, 13, 23, doc);

  if (page.title2) {
    pageTitle(page.title2, 13, 33, doc);
  }

  if (page.title3) {
    pageTitle(page.title3, 13, 42, doc);
  }

  const chartData = getChartData(page.ref.current.props.data, page.pie);

  doc.setTextColor("#D4584A");
  doc.setFontSize(50);
  doc.setFont("helvetica", "bold");
  doc.textCenter(chartData.total + " kg", { align: "center" }, 0, 70);

  chartData.items.forEach(({ color, text, amount }, i) => {
    doc.setFillColor(color);

    const Y = 185;
    const deltaX = (i % 2) * 95;
    doc.rect(15 + deltaX, Y + i * 4 - (i % 2) * 4, 5, 5, "F");

    doc.setTextColor("#515452");
    doc.setFontSize(16);
    doc.setFont("helvetica", "normal");
    doc.text(text, 23 + deltaX, Y + 5 + i * 4 - (i % 2) * 4);

    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    const width = doc.getTextWidth(amount + " kg");
    doc.text(amount + " kg", 23 + deltaX + 75 - width, Y + 5 + i * 4 - (i % 2) * 4);
  });

  const chart1 = getImage(page.ref);
  const height = 180 / chart1.ratio;
  doc.addImage(chart1.base64, "PNG", 15, 85, 180, height);

  // grid(doc);
}

function equivalentPage(doc, page) {
  const chartData = getChartData(page.ref.current.props.data);

  // 1 kg of saved co2 = 7 Mils tågkörning

  // 1 kg of saved co2 = 0,4 mils resa med bil

  // 1 kg of saved co2 = 3 Timmars dammsugning

  const items = [
    {
      src: train_png,
      amount: format(7 * chartData.total),
      text: "mils tågresande",
    },
    {
      src: car_png,
      amount: format(0.4 * chartData.total),
      text: "mils bilkörning",
    },
    {
      src: vacuum_png,
      amount: format(3 * chartData.total),
      text: "timmars dammsugning",
      text2: "med grön el",
    },
  ];

  doc.setTextColor("#77817B");
  doc.setFillColor("#77817B");
  const factor = 20;
  doc.addImage(esmartGreen, "PNG", a4.width - 820 / factor - 10, 10, 820 / factor, 390 / factor);

  pageTitle(page.title, 13, 23, doc);

  if (page.title2) {
    pageTitle(page.title2, 13, 32, doc);
  }

  if (page.title3) {
    pageTitle(page.title3, 13, 42, doc);
  }

  doc.setTextColor("#D4584A");
  doc.setFontSize(50);
  doc.setFont("helvetica", "bold");
  doc.textCenter(chartData.total + " kg CO2", { align: "center" }, 0, 70);

  items.forEach((item, i) => {
    doc.addImage(item.src, "PNG", 43, 90 + i * 53, 40, 40);

    doc.setTextColor("#D4584A");
    doc.setFontSize(30);
    doc.setFont("helvetica", "bold");
    doc.text(item.amount, 43 + 55, 110 + i * 53);

    doc.setTextColor("#77817B");
    doc.setFontSize(20);
    doc.setFont("helvetica", "bold");
    doc.text(item.text, 43 + 55, 120 + i * 53);

    if (item.text2) {
      doc.setTextColor("#77817B");
      doc.setFontSize(20);
      doc.setFont("helvetica", "bold");
      doc.text(item.text2, 43 + 55, 128 + i * 53);
    }
  });

  // grid(doc);
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getPlacementByInterval(timeInterval, index) {
  if (index === 1) {
    if (timeInterval === intervals["3_MONTHS"] || timeInterval === intervals["6_MONTHS"]) return 40;

    if (timeInterval === intervals["12_MONTHS"]) return 60;
  }

  if (index === 2) {
    if (timeInterval === intervals["3_MONTHS"] || timeInterval === intervals["6_MONTHS"]) return 80;

    if (timeInterval === intervals["12_MONTHS"]) return 125;
  }

  if (index === 3) {
    if (timeInterval === intervals["3_MONTHS"] || timeInterval === intervals["6_MONTHS"])
      return 120;

    if (timeInterval === intervals["12_MONTHS"]) return 190;
  }

  if (index === 4) {
    if (timeInterval === intervals["3_MONTHS"] || timeInterval === intervals["6_MONTHS"])
      return 160;

    if (timeInterval === intervals["12_MONTHS"]) return 225;
  }

  if (index === 5) {
    if (timeInterval === intervals["3_MONTHS"] || timeInterval === intervals["6_MONTHS"])
      return 200;
  }

  if (index === 6) {
    if (timeInterval === intervals["3_MONTHS"] || timeInterval === intervals["6_MONTHS"])
      return 240;
  }

  return 100;
}

function materialPage(doc, page, index, timeInterval) {
  doc.setTextColor("#77817B");
  doc.setFillColor("#77817B");

  const factor = 20;

  doc.addImage(esmartGreen, "PNG", a4.width - 820 / factor - 10, 10, 820 / factor, 390 / factor);

  pageTitle("Total Besparat Co2 per månad (återvinning och transporter)", 8, 23, doc, 14);

  let typeText = 37;

  let totaltText = 41;

  let totalAmount = 41;

  let icon = 33;

  let body = 45;

  if (index === 1) {
    let placementAdjustment = getPlacementByInterval(timeInterval, index);

    totaltText = totaltText + placementAdjustment;
    totalAmount = totalAmount + placementAdjustment;
    typeText = typeText + placementAdjustment;
    icon = icon + placementAdjustment;
    body = body + placementAdjustment;
  }

  if (index === 2) {
    let placementAdjustment = getPlacementByInterval(timeInterval, index);

    totaltText = totaltText + placementAdjustment;
    totalAmount = totalAmount + placementAdjustment;
    typeText = typeText + placementAdjustment;
    icon = icon + placementAdjustment;
    body = body + placementAdjustment;
  }

  if (index === 3) {
    let placementAdjustment = getPlacementByInterval(timeInterval, index);

    totaltText = totaltText + placementAdjustment;
    totalAmount = totalAmount + placementAdjustment;
    typeText = typeText + placementAdjustment;
    icon = icon + placementAdjustment;
    body = body + placementAdjustment;
  }

  if (index === 4) {
    let placementAdjustment = getPlacementByInterval(timeInterval, index);

    totaltText = totaltText + placementAdjustment;
    totalAmount = totalAmount + placementAdjustment;
    typeText = typeText + placementAdjustment;
    icon = icon + placementAdjustment;
    body = body + placementAdjustment;
  }

  if (index === 5) {
    let placementAdjustment = getPlacementByInterval(timeInterval, index);

    totaltText = totaltText + placementAdjustment;
    totalAmount = totalAmount + placementAdjustment;
    typeText = typeText + placementAdjustment;
    icon = icon + placementAdjustment;
    body = body + placementAdjustment;
  }

  if (index === 6) {
    let placementAdjustment = getPlacementByInterval(timeInterval, index);

    totaltText = totaltText + placementAdjustment;
    totalAmount = totalAmount + placementAdjustment;
    typeText = typeText + placementAdjustment;
    icon = icon + placementAdjustment;
    body = body + placementAdjustment;
  }

  doc.setTextColor("#78817B");
  doc.setFontSize(10);
  doc.setFont("helvetica", "bold");
  doc.textCenter("Totalt:", { align: "left" }, 19, totaltText);

  doc.setTextColor("#D4584A");
  doc.setFont("helvetica", "bold");
  doc.setFontSize(10);
  doc.textCenter(page.total + " kg", { align: "left" }, 32, totalAmount);

  doc.setTextColor("#78817B");
  doc.setFont("helvetica", "bold");
  doc.setFontSize(10);
  doc.textCenter(capitalizeFirstLetter(page.type), { align: "left" }, 19, typeText);

  const items = page.items.reduce((acc, item, i) => {
    if (i % 3 === 0) {
      acc.push([]);
    }
    acc[acc.length - 1].push(item.text);
    acc[acc.length - 1].push(item.amount + " kg");

    return acc;
  }, []);

  doc.setLineWidth(0);

  doc.autoTable({
    body: items,
    startY: body,
    margin: { left: 8, right: 0 },
    theme: "plain",
    font: "helvetica",
    columnStyles: {
      1: { fontStyle: "bold", textColor: "#78817B" },
      2: {},
      3: { fontStyle: "bold", textColor: "#78817B" },
      4: {},
      5: { fontStyle: "bold", textColor: "#78817B" },
      6: {},
    },
  });

  const iconSrc = nameToIconBase64Map[page.type] || other_png;

  const size = 8;

  doc.addImage(iconSrc, "PNG", 25 / 2 - size / 2, icon, size, size, undefined, "FAST");
}

// eslint-disable-next-line no-unused-vars
function grid(doc) {
  doc.setDrawColor("#e7e7e7");

  for (let i = 0; i < 21; i++) {
    doc.line(i * 10, 0, i * 10, 297);
  }

  for (let j = 0; j < 30; j++) {
    doc.line(0, j * 10, 210, j * 10);
  }
}

export function getChartData(data, pie) {
  let total = 0;
  let items = [];
  if (pie) {
    if (data && data.datasets && data.datasets[0]) {
      const datasets = data.datasets[0];
      items = data.labels.map((text, i) => {
        total += datasets.data[i];
        return {
          text,
          color: datasets.backgroundColor[i],
          amount: format(datasets.data[i]),
        };
      });
    }
    total = format(total);
    return { total, items };
  }

  const datasets = data.datasets;
  const values = datasets.map((item) => {
    if (Object.values(months).includes(item.label)) {
      return item.data.map((value, idx) => {
        const amount = isNaN(value) ? 0 : value;
        total += amount;
        return {
          color: item.borderColor,
          text: item.label.replace("(Kg)", "").trim() + " " + data.labels[idx],
          amount: format(amount),
        };
      });
    }

    const amount = item.data.reduce((a, b) => {
      if (isNaN(b)) {
        return a;
      }
      return a + b || 0;
    });

    total += amount;
    return [
      {
        color: item.borderColor,
        text: item.label.replace("(Kg)", "").trim(),
        amount: format(amount),
      },
    ];
  });

  items = [].concat.apply([], values);

  total = (Math.round((total + Number.EPSILON) * 10) / 10).toFixed(1);

  return { total, items };
}

export function getMaterialData(data) {
  if (!data) {
    return [];
  }

  const datasets = data.datasets;

  return datasets
    .map((item) => {
      const amount = item.data.reduce((a, b) => {
        if (isNaN(b)) {
          return a;
        }
        return a + b || 0;
      });

      if (item.label.replace("(Kg)", "").trim() === "Övrigt") {
        return null;
      }
      return {
        type: item.label.replace("(Kg)", "").trim(),
        total: format(amount),
        items: data.labels.map((l, i) => {
          const value = isNaN(item.data[i]) ? 0 : item.data[i];

          const parts = l.split(" - ");
          return {
            text: months[parts[1]] + " " + parts[0],
            amount: format(value),
          };
        }),
      };
    })
    .filter((a) => a);
}

// function title(text, x, y, doc) {
//   doc.setFont('helvetica', 'bold');
//   doc.setFontSize(20);
//   doc.text(text, x, y);
// }

function pageTitle(text, x, y, doc, fontSize) {
  doc.setFont("helvetica", "bold");
  doc.setFontSize(fontSize ?? 20);
  doc.text(text, x, y);
}

function getImage(ref) {
  const doc = new jsPDF();

  const base64 = ref.current.chartInstance.toBase64Image();
  const { width, height } = doc.getImageProperties(base64);
  const ratio = (width * 1.0) / (height * 1.0);
  return {
    ratio,
    width,
    height,
    base64,
  };
}

(function (API) {
  API.textCenter = function (txt, options, x, y) {
    options = options || {};
    /* Use the options align property to specify desired text alignment
     * Param x will be ignored if desired text alignment is 'center'.
     * Usage of options can easily extend the function to apply different text
     * styles and sizes
     */
    if (options.align === "center") {
      // Get current font size
      var fontSize = this.internal.getFontSize();

      // Get page width
      var pageWidth = this.internal.pageSize.width;

      // Get the actual text's width
      /* You multiply the unit width of your string by your font size and divide
       * by the internal scale factor. The division is necessary
       * for the case where you use units other than 'pt' in the constructor
       * of jsPDF.
       */
      const txtWidth = (this.getStringUnitWidth(txt) * fontSize) / this.internal.scaleFactor;

      // Calculate text's x coordinate
      x = (pageWidth - txtWidth) / 2;
    }

    // Draw text at x,y
    this.text(txt, x, y);
  };
})(jsPDF.API);

var splitRegex = /\r\n|\r|\n/g;
jsPDF.API.textRight = function (text, x, y, hAlign, vAlign) {
  var fontSize = this.internal.getFontSize() / this.internal.scaleFactor;

  // As defined in jsPDF source code
  var lineHeightProportion = 1.15;

  var splittedText = null;
  var lineCount = 1;
  if (vAlign === "middle" || vAlign === "bottom" || hAlign === "center" || hAlign === "right") {
    splittedText = typeof text === "string" ? text.split(splitRegex) : text;

    lineCount = splittedText.length || 1;
  }

  // Align the top
  y += fontSize * (2 - lineHeightProportion);

  if (vAlign === "middle") y -= (lineCount / 2) * fontSize;
  else if (vAlign === "bottom") y -= lineCount * fontSize;

  if (hAlign === "center" || hAlign === "right") {
    var alignSize = fontSize;
    if (hAlign === "center") alignSize *= 0.5;

    if (lineCount > 1) {
      for (var iLine = 0; iLine < splittedText.length; iLine++) {
        this.text(
          splittedText[iLine],
          x - this.getStringUnitWidth(splittedText[iLine]) * alignSize,
          y
        );
        y += fontSize * lineHeightProportion;
      }
      return this;
    }
    x -= this.getStringUnitWidth(text) * alignSize;
  }

  this.text(text, x, y);
  return this;
};
