const axios = require("axios");
const qs = require("querystring");

const baseUrl = "https://minasidor.smartrecycling.se/api/";
// const baseUrl = "http://minasidortest.smartrecycling.se/api/";

// const eSmartAPIUrl = "http://localhost:5001/api/v1/";
const eSmartAPIUrl = "https://api.smartrecycling.se/api/v1/";

const eSmartNewsUrl = "https://www.smartrecycling.se/wp-json/wp/v2/posts";

async function sendEmail(to, data, filename, subject, contentType, dataUrl, text) {
  const url = eSmartAPIUrl + "email";
  const res = await axios.post(
    url,
    {
      to: to,
      subject: subject || "Rapport från eSmart",
      file: data,
      filename: filename || "Rapport och statistik.pdf",
      contentType: contentType || "application/pdf",
      dataUrl: dataUrl,
      text: text,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return res;
}

// async function getDataFromUrl(fileUrl) {
//   const url = eSmartAPIUrl + 'get-data-from-url';
//   return await axios.post(
//     url,
//     {
//       to: to,
//       subject: subject || 'Rapport från eSmart',
//       file: data,
//       filename: filename || 'Rapport och statistik.pdf',
//     },
//     {
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     }
//   );
// }

function authConfig() {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
}

function xWwwFormUrlencodedConfig() {
  return {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
}

function applicationJsonConfig() {
  return {
    headers: {
      "Content-Type": "application/json",
    },
  };
}

function applicationJsonConfigWidthAuth() {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
}

function login(username, password) {
  const url = baseUrl + "token";

  const body = {
    grant_type: "password",
    username,
    password,
  };

  return axios.post(url, qs.stringify(body), xWwwFormUrlencodedConfig());
}

const Account = {};
// Get customer number from org.nr.
Account.Kundnr = (personnr) => {
  const url = `${baseUrl}api/Account/Kundnr/${personnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Get customer number from org.nr.
Account.KundnrPost = (email) => {
  const url = `${baseUrl}api/Account/KundnrPost/`;

  const data = {
    email,
  };

  return axios.post(url, data, applicationJsonConfigWidthAuth());
};

Account.Register = (kundnr, password, newPassword) => {
  const url = `${baseUrl}api/Account/Register`;

  const data = {
    UserName: kundnr,
    Password: newPassword,
    ConfirmPassword: newPassword,
    InitialPassword: password,
  };

  return axios.post(url, data, applicationJsonConfig());

  // return axios.post(url, data, authConfig()).then((res) => res.data);
};

const external = {};
// Autocomplete function for addresses that exists in Thor
external.autocomplete = (search) => {
  const url = `${baseUrl}api/external/autocomplete/${search}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Autocomplete function for addresses that exists in Thor
external.autocompleteAll = (search) => {
  const url = `${baseUrl}api/external/autocompleteAll/${search}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Gets schedule for an address works best with if inputed with the addresses from Autocomplete function.
external.schedule = (search) => {
  const url = `${baseUrl}api/external/schedule/${search}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

const form = {};
// Returns list of products
form.productlist = () => {
  const url = `${baseUrl}api/form/productlist`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Registers product change
form.register = () => {
  const url = `${baseUrl}api/form/register`;
  return axios.post(url, authConfig()).then((res) => res.data);
};

// Validate customer
form.validate = () => {
  const url = `${baseUrl}api/form/validate`;
  return axios.post(url, authConfig()).then((res) => res.data);
};

const Profile = {};
// Get locations for customer
Profile.Locations = (kundnr, kundgrupp = "--") => {
  const grupp = kundgrupp === "Alla" ? "--" : kundgrupp;
  const url = `${baseUrl}api/Profile/Locations/${kundnr}/${grupp}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Gets info on customer
Profile.Info = (kundnr) => {
  const url = `${baseUrl}api/Profile/Info/${kundnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Gets customer profile
Profile.My = (kundnr) => {
  const url = `${baseUrl}api/Profile/My/${kundnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Get contracts
Profile.Avtal = (kundnr) => {
  const url = `${baseUrl}api/Profile/Avtal/${kundnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Edit customer phone, email and SMS
Profile.Edit = (kundnr) => {
  const url = `${baseUrl}api/Profile/Edit/${kundnr}`;
  return axios.post(url, authConfig()).then((res) => res.data);
};

// Updates customer contact info
Profile.Update = (kundnr, data) => {
  const url = `${baseUrl}api/Profile/Update/${kundnr}`;
  return axios.post(url, data, authConfig()).then((res) => res.data);
};

// Updates fa customer contact info
Profile.UpdateFA = (kundnr, data) => {
  const url = `${baseUrl}api/Profile/UpdateFA/${kundnr}`;
  return axios.post(url, data, authConfig()).then((res) => res.data);
};

// Updates avtal customer contact info
Profile.UpdateAvtal = (kundnr, data) => {
  const url = `${baseUrl}api/Profile/UpdateAvtal/${kundnr}`;
  return axios.post(url, data, authConfig()).then((res) => res.data);
};

// Returns customer invoices
Profile.Fakturor = (kundnr) => {
  const url = `${baseUrl}api/Profile/Fakturor/${kundnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns previous visit
Profile.Foregaende = (kundnr) => {
  const url = `${baseUrl}api/Profile/Foregaende/${kundnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns prevoius visit per address
Profile.ForegaendeFranHs = (kundnr, hsnr) => {
  const url = `${baseUrl}api/Profile/ForegaendeFranHs/${kundnr}/${hsnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns next visit
Profile.Nasta = (kundnr) => {
  const url = `${baseUrl}api/Profile/Nasta/${kundnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns next visit per address
Profile.NastaFranHs = (kundnr, hsnr) => {
  const url = `${baseUrl}api/Profile/NastaFranHs/${kundnr}/${hsnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Get GDPR acceptance
Profile.GetGdprAccepterat = () => {
  const url = `${baseUrl}api/Profile/GetGdprAccepterat`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Set GDPR acceptance
Profile.SetGdprAccepterat = () => {
  const url = `${baseUrl}api/Profile/SetGdprAccepterat`;
  return axios.post(url, authConfig()).then((res) => res.data);
};

// Get show statistics
Profile.GetVisaStatistik = () => {
  const url = `${baseUrl}api/Profile/GetVisaStatistik`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Get phone number
Profile.GetTelefonnummer = () => {
  const url = `${baseUrl}api/Profile/GetTelefonnummer`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

const Reports = {};

// Returns statistics in Excel
Reports.GetKundstatistikExcel = (kundnr, from, to, hsnr, kundgrupp = "--") => {
  const grupp = kundgrupp === "Alla" ? "--" : kundgrupp;
  const url = `${baseUrl}api/Reports/Excel/GetKundstatistikExcel/${kundnr}/${from}/${to}/${hsnr}/${grupp}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns detaild Excel statistics
Reports.Detailed = (kundnr, from, to, hsnr, kundgrupp = "--") => {
  const grupp = kundgrupp === "Alla" ? "--" : kundgrupp;
  const url = `${baseUrl}api/Reports/Excel/Detailed/${kundnr}/${from}/${to}/${hsnr}/${grupp}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns invoice
Reports.Faktura = (kundnr, fakturanr) => {
  const url = `${baseUrl}api/Reports/Pdf/Faktura/${kundnr}/${fakturanr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns secrecy diploma
Reports.FakturaOld = (kundnr, fakturanr) => {
  const url = `${baseUrl}//api/Reports/Pdf/FakturaOld/${kundnr}/${fakturanr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};
Reports.Sekretessintyg = (kundnr, ordernr) => {
  const url = `${baseUrl}api/Reports/Pdf/Sekretessintyg/${kundnr}/${ordernr}`;
  return axios.get(url, { ...authConfig(), responseType: "arraybuffer" }).then((res) => res.data);
};

// Returns Diploma
Reports.Diplom = (kundnr, kundgrupp = "--") => {
  const grupp = kundgrupp === "Alla" ? "--" : kundgrupp;
  const url = `${baseUrl}api/Reports/Pdf/Diplom/${kundnr}/${grupp}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns PDF report
Reports.ReportPdf = (kundnr, from, avftyp) => {
  const url = `${baseUrl}api/Reports/Pdf/Report/${kundnr}/${from}/${avftyp}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns report
Reports.Report = () => {
  const url = `${baseUrl}api/Report`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

const Statistik = {};
// Returns contribution
Statistik.Bidrag = (kundnr) => {
  const url = `${baseUrl}api/Statistik/Bidrag/${kundnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns contribution recycling
Statistik.FordelningAtervinning = (kundnr, hsnr, from, to, kundgrupp = "--") => {
  const grupp = kundgrupp === "Alla" ? "--" : kundgrupp;
  const url = `${baseUrl}api/Statistik/FordelningAtervinning/${kundnr}/${hsnr}/${from}/${to}/${grupp}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns CO2 savings
Statistik.BesparingCO2 = (kundnr, hsnr, from, to, kundgrupp = "--") => {
  const grupp = kundgrupp === "Alla" ? "--" : kundgrupp;
  const url = `${baseUrl}api/Statistik/BesparingCO2/${kundnr}/${hsnr}/${from}/${to}/${grupp}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns Equivalents
Statistik.Ekvivalenter = (kundnr, hsnr, from, to, kundgrupp = "--") => {
  const grupp = kundgrupp === "Alla" ? "--" : kundgrupp;
  const url = `${baseUrl}api/Statistik/Ekvivalenter/${kundnr}/${hsnr}/${from}/${to}/${grupp}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns CO2 savings trip length
Statistik.BesparingCO2Korstracka = (kundnr, hsnr, from, to, kundgrupp = "--") => {
  const grupp = kundgrupp === "Alla" ? "--" : kundgrupp;
  const url = `${baseUrl}api/Statistik/BesparingCO2Korstracka/${kundnr}/${hsnr}/${from}/${to}/${grupp}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns savings equivalents
Statistik.BesparingMotsvarar = (kundnr, hsnr, from, to, kundgrupp = "--") => {
  const grupp = kundgrupp === "Alla" ? "--" : kundgrupp;
  const url = `${baseUrl}api/Statistik/BesparingMotsvarar/${kundnr}/${hsnr}/${from}/${to}/${grupp}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns material per month
Statistik.MaterialPerManad = (kundnr, hsnr, from, to, kundgrupp = "--") => {
  const grupp = kundgrupp === "Alla" ? "--" : kundgrupp;
  const url = `${baseUrl}api/Statistik/MaterialPerManad/${kundnr}/${hsnr}/${from}/${to}/${grupp}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns graph for waste types
Statistik.GrafAvfallstyper = (kundnr, hsnr, from, to, kundgrupp = "--") => {
  const grupp = kundgrupp === "Alla" ? "--" : kundgrupp;
  const url = `${baseUrl}api/Statistik/GrafAvfallstyper/${kundnr}/${hsnr}/${from}/${to}/${grupp}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

const FA = {};

FA.Signera = (Kundnr, Ordernr, Svg, Fornamn, Efternamn) => {
  const url = `${baseUrl}api/FA/Signera`;

  const data = {
    Svg,
    Kundnr,
    Ordernr,
    Fornamn,
    Efternamn,
  };

  return axios.post(url, data, applicationJsonConfigWidthAuth());
};

// Returns transportation documentation
Statistik.Transportdokument = (kundnr) => {
  const url = `${baseUrl}api/Statistik/Transportdokument2/${kundnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns graph for waste types
Statistik.ReturIT = (kundnr, hsnr, from, to, kundgrupp = "--") => {
  const grupp = kundgrupp === "Alla" ? "--" : kundgrupp;
  console.log(!!grupp);
  const url = `${baseUrl}api/Statistik/Transportdokument2/${kundnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

const Tjanster = {};
// Returns orders
Tjanster.Bestallningar = (kundnr) => {
  const url = `${baseUrl}api/Tjanster/Bestallningar/${kundnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns orders grouped
Tjanster.BestallningarGrupperad = (kundnr) => {
  const url = `${baseUrl}api/Tjanster/BestallningarGrupperad/${kundnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns orders per address
Tjanster.BestallningarGrupperadPerHamtstalle = () => {
  const url = `${baseUrl}api/Tjanster/BestallningarGrupperadPerHamtstalle`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns charity
Tjanster.Charity = (kundnr) => {
  const url = `${baseUrl}api/Tjanster/Charity/${kundnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns my services
Tjanster.Mina = (kundnr) => {
  const url = `${baseUrl}api/Tjanster/Mina/${kundnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns services
Tjanster.GetTjanster = () => {
  const url = `${baseUrl}api/Tjanster/GetTjanster`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns services
Tjanster.Get = (kundnr) => {
  const url = `${baseUrl}api/Tjanster/Get/${kundnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns customers
Tjanster.GetKunder = () => {
  const url = `${baseUrl}api/Tjanster/GetKunder`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns summary per waste type
Tjanster.Summering = (kundnr, from, to, id) => {
  const url = `${baseUrl}api/Tjanster/Avftyper/Summering/${kundnr}/${from}/${to}/${id}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns summary per price type and waste type
Tjanster.SummeringPristyp = (kundnr, from, to, id) => {
  const url = `${baseUrl}api/Tjanster/Avftyper/SummeringPristyp/${kundnr}/${from}/${to}/${id}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns summary per material
Tjanster.SummeringMaterial = (kundnr, from, to, id, kundgrupp = "--") => {
  const grupp = kundgrupp === "Alla" ? "--" : kundgrupp;
  const url = `${baseUrl}api/Tjanster/Avftyper/SummeringMaterial/${kundnr}/${from}/${to}/${id}/${grupp}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns services with prices
Tjanster.GetTjansterMedPrissatt = (kundnr, hsnr) => {
  const url = `${baseUrl}api/Tjanster/GetTjansterMedPrissatt/${kundnr}/${hsnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns secrecy
Tjanster.GetSekretess = (kundnr, hsnr) => {
  const url = `${baseUrl}api/Tjanster/GetSekretess/${kundnr}/${hsnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns services per address
Tjanster.GetFranHs = (kundnr, hsnr) => {
  const url = `${baseUrl}api/Tjanster/GetFranHs/${kundnr}/${hsnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns services with order capability per customer
Tjanster.GetTjansterBestallning = (kundnr) => {
  const url = `${baseUrl}api/Tjanster/GetTjansterBestallning/${kundnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns services with order capability
Tjanster.GetTjansterForBestallning = () => {
  const url = `${baseUrl}api/Tjanster/GetTjansterForBestallning`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns order types
Tjanster.Typer = (kundnr) => {
  const url = `${baseUrl}api/Tjanster/Typer/${kundnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Returns order prices
Tjanster.GetBestallningsPriser = () => {
  const url = `${baseUrl}api/Tjanster/GetBestallningsPriser`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Create new order per customer
Tjanster.NewOrder = (kundnr) => {
  const url = `${baseUrl}api/Tjanster/NewOrder/${kundnr}`;
  return axios.post(url, authConfig()).then((res) => res.data);
};

// Create new order
Tjanster.NewOrder2 = () => {
  const url = `${baseUrl}api/Tjanster/NewOrder`;
  return axios.post(url, authConfig()).then((res) => res.data);
};

// Get service calendar
Tjanster.GetTurschema = (kundnr) => {
  const url = `${baseUrl}api/Tjanster/GetTurschema/${kundnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Get service calendar per address
Tjanster.GetTurschemaFranHs = (kundnr, hsnr) => {
  const url = `${baseUrl}api/Tjanster/GetTurschemaFranHs/${kundnr}/${hsnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Get colorized service calendar
Tjanster.GetTurschemaColorized = (kundnr) => {
  const url = `${baseUrl}api/Tjanster/GetTurschemaColorized/${kundnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

// Upgrade customer to premium
Tjanster.UppgraderaTillPremium = (kundnr) => {
  const url = `${baseUrl}api/Tjanster/UppgraderaTillPremium/${kundnr}`;
  return axios.post(url, authConfig()).then((res) => res.data);
};

// Upgrad to premium with comment
Tjanster.UppgraderaTillPremiumMedKommentar = () => {
  const url = `${baseUrl}api/Tjanster/UppgraderaTillPremiumMedKommentar`;
  return axios.post(url, authConfig()).then((res) => res.data);
};

// Create case
Tjanster.SkapaArende = () => {
  const url = `${baseUrl}api/Tjanster/SkapaArende`;
  return axios.post(url, authConfig()).then((res) => res.data);
};

// Get file structure for shared files
Tjanster.GetFilstruktur = (kundnr) => {
  const url = `${baseUrl}api/Tjanster/GetFilstruktur/${kundnr}`;
  return axios.get(url, authConfig()).then((res) => res.data);
};

const News = {};

News.posts = () => {
  return axios.get(eSmartNewsUrl, applicationJsonConfig()).then((res) =>
    (res.data || []).filter((post) => {
      return (post.categories || []).indexOf(44) !== -1;
    })
  );
};

export default {
  FA,
  News,
  login,
  Account,
  external,
  form,
  Profile,
  Reports,
  Statistik,
  Tjanster,
  sendEmail,
  // getDataFromUrl,
};
