import AssignmentIcon from "@material-ui/icons/Assignment";
import BarChartIcon from "@material-ui/icons/BarChart";
import BusinessIcon from "@material-ui/icons/Business";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import LockIcon from "@material-ui/icons/Lock";
import MoneyIcon from "@material-ui/icons/Money";
import Calendar from "views/Calendar/Calendar.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import News from "views/Dashboard/News";
import Report from "views/Dashboard/Report.js";
import LoginPage from "views/Pages/LoginPage.js";
import PricingPage from "views/Pages/PricingPage.js";
import SecretPage from "views/Pages/SecretPage";
import TransportPage from "views/Pages/TransportPage";
import UserProfile from "views/Pages/UserProfile.js";
import NewsIcon from "@material-ui/icons/Subtitles";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },

  {
    path: "/report",
    name: "Rapportering och statistik",
    rtlName: "لوحة القيادة",
    icon: BarChartIcon,
    component: Report,
    layout: "/admin",
  },
  {
    path: "/calendar",
    name: "Hämtning & avvikelser",
    rtlName: "التقويم",
    icon: EventIcon,
    component: Calendar,
    layout: "/admin",
  },

  {
    path: "/user-page",
    name: "Mina uppgifter",
    rtlName: "ملف تعريفي للمستخدم",
    mini: "UP",
    rtlMini: "شع",
    icon: BusinessIcon,
    component: UserProfile,
    layout: "/admin",
  },

  {
    path: "/transport",
    name: "Farligt avfall",
    rtlName: "التقويم",
    icon: AssignmentIcon,
    component: TransportPage,
    layout: "/admin",
  },
  {
    path: "/pricing-page",
    name: "Prissättning",
    rtlName: "التقويم",
    icon: MoneyIcon,
    component: PricingPage,
    layout: "/admin",
  },
  {
    path: "/secret-page",
    name: "Sekretess",
    rtlName: "التقويم",
    icon: LockIcon,
    component: SecretPage,
    layout: "/admin",
  },
  {
    path: "/news",
    name: "Nyheter",
    rtlName: "التقويم",
    icon: NewsIcon,
    component: News,
    layout: "/admin",
  },
  /*
  {
    path: '/reuseit',
    name: 'Returhantering IT',
    rtlName: 'التقويم',
    icon: ComputerIcon,
    component: ReuseITPage,
    layout: '/admin',
  },
  */
  {
    ignore: true,
    path: "/login-page",
    name: "Login Page",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    component: LoginPage,
    layout: "/auth",
  },
  /*
  {
    collapse: true,
    name: 'Pages',
    rtlName: 'صفحات',
    icon: Image,
    state: 'pageCollapse',
    views: [
      {
        path: '/pricing-page',
        name: 'Pricing Page',
        rtlName: 'عالتسعير',
        mini: 'PP',
        rtlMini: 'ع',
        component: PricingPage,
        layout: '/admin',
      },
      {
        path: '/rtl-support-page',
        name: 'RTL Support',
        rtlName: 'صودعم رتل',
        mini: 'RS',
        rtlMini: 'صو',
        component: RTLSupport,
        layout: '/rtl',
      },
      {
        path: '/timeline-page',
        name: 'Timeline Page',
        rtlName: 'تيالجدول الزمني',
        mini: 'T',
        rtlMini: 'تي',
        component: TimelinePage,
        layout: '/admin',
      },
      {
        path: '/login-page',
        name: 'Login Page',
        rtlName: 'هعذاتسجيل الدخول',
        mini: 'L',
        rtlMini: 'هعذا',
        component: LoginPage,
        layout: '/auth',
      },
      {
        path: '/register-page',
        name: 'Register Page',
        rtlName: 'تسجيل',
        mini: 'R',
        rtlMini: 'صع',
        component: RegisterPage,
        layout: '/auth',
      },
      {
        path: '/lock-screen-page',
        name: 'Lock Screen Page',
        rtlName: 'اقفل الشاشة',
        mini: 'LS',
        rtlMini: 'هذاع',
        component: LockScreenPage,
        layout: '/auth',
      },

      {
        path: '/error-page',
        name: 'Error Page',
        rtlName: 'صفحة الخطأ',
        mini: 'E',
        rtlMini: 'البريد',
        component: ErrorPage,
        layout: '/auth',
      },
    ],
  },
  {
    collapse: true,
    name: 'Components',
    rtlName: 'المكونات',
    icon: Apps,
    state: 'componentsCollapse',
    views: [
      {
        collapse: true,
        name: 'Multi Level Collapse',
        rtlName: 'انهيار متعدد المستويات',
        mini: 'MC',
        rtlMini: 'ر',
        state: 'multiCollapse',
        views: [
          {
            path: '/buttons',
            name: 'Buttons',
            rtlName: 'وصفت',
            mini: 'B',
            rtlMini: 'ب',
            component: Buttons,
            layout: '/admin',
          },
        ],
      },
      {
        path: '/buttons',
        name: 'Buttons',
        rtlName: 'وصفت',
        mini: 'B',
        rtlMini: 'ب',
        component: Buttons,
        layout: '/admin',
      },
      {
        path: '/grid-system',
        name: 'Grid System',
        rtlName: 'نظام الشبكة',
        mini: 'GS',
        rtlMini: 'زو',
        component: GridSystem,
        layout: '/admin',
      },
      {
        path: '/panels',
        name: 'Panels',
        rtlName: 'لوحات',
        mini: 'P',
        rtlMini: 'ع',
        component: Panels,
        layout: '/admin',
      },
      {
        path: '/sweet-alert',
        name: 'Sweet Alert',
        rtlName: 'الحلو تنبيه',
        mini: 'SA',
        rtlMini: 'ومن',
        component: SweetAlert,
        layout: '/admin',
      },
      {
        path: '/notifications',
        name: 'Notifications',
        rtlName: 'إخطارات',
        mini: 'N',
        rtlMini: 'ن',
        component: Notifications,
        layout: '/admin',
      },
      {
        path: '/icons',
        name: 'Icons',
        rtlName: 'الرموز',
        mini: 'I',
        rtlMini: 'و',
        component: Icons,
        layout: '/admin',
      },
      {
        path: '/typography',
        name: 'Typography',
        rtlName: 'طباعة',
        mini: 'T',
        rtlMini: 'ر',
        component: Typography,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Forms',
    rtlName: 'إستمارات',
    icon: 'content_paste',
    state: 'formsCollapse',
    views: [
      {
        path: '/regular-forms',
        name: 'Regular Forms',
        rtlName: 'أشكال عادية',
        mini: 'RF',
        rtlMini: 'صو',
        component: RegularForms,
        layout: '/admin',
      },
      {
        path: '/extended-forms',
        name: 'Extended Forms',
        rtlName: 'نماذج موسعة',
        mini: 'EF',
        rtlMini: 'هوو',
        component: ExtendedForms,
        layout: '/admin',
      },
      {
        path: '/validation-forms',
        name: 'Validation Forms',
        rtlName: 'نماذج التحقق من الصحة',
        mini: 'VF',
        rtlMini: 'تو',
        component: ValidationForms,
        layout: '/admin',
      },
      {
        path: '/wizard',
        name: 'Wizard',
        rtlName: 'ساحر',
        mini: 'W',
        rtlMini: 'ث',
        component: Wizard,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Tables',
    rtlName: 'الجداول',
    icon: GridOn,
    state: 'tablesCollapse',
    views: [
      {
        path: '/regular-tables',
        name: 'Regular Tables',
        rtlName: 'طاولات عادية',
        mini: 'RT',
        rtlMini: 'صر',
        component: RegularTables,
        layout: '/admin',
      },
      {
        path: '/extended-tables',
        name: 'Extended Tables',
        rtlName: 'جداول ممتدة',
        mini: 'ET',
        rtlMini: 'هور',
        component: ExtendedTables,
        layout: '/admin',
      },
      {
        path: '/react-tables',
        name: 'React Tables',
        rtlName: 'رد فعل الطاولة',
        mini: 'RT',
        rtlMini: 'در',
        component: ReactTables,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Maps',
    rtlName: 'خرائط',
    icon: Place,
    state: 'mapsCollapse',
    views: [
      {
        path: '/google-maps',
        name: 'Google Maps',
        rtlName: 'خرائط جوجل',
        mini: 'GM',
        rtlMini: 'زم',
        component: GoogleMaps,
        layout: '/admin',
      },
      {
        path: '/full-screen-maps',
        name: 'Full Screen Map',
        rtlName: 'خريطة كاملة الشاشة',
        mini: 'FSM',
        rtlMini: 'ووم',
        component: FullScreenMap,
        layout: '/admin',
      },
      {
        path: '/vector-maps',
        name: 'Vector Map',
        rtlName: 'خريطة المتجه',
        mini: 'VM',
        rtlMini: 'تم',
        component: VectorMap,
        layout: '/admin',
      },
    ],
  },
  {
    path: '/widgets',
    name: 'Widgets',
    rtlName: 'الحاجيات',
    icon: WidgetsIcon,
    component: Widgets,
    layout: '/admin',
  },
  {
    path: '/charts',
    name: 'Charts',
    rtlName: 'الرسوم البيانية',
    icon: Timeline,
    component: Charts,
    layout: '/admin',
  } /* */
].filter((a) => a);
export default dashRoutes;
