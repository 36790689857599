// @material-ui/core components
import api from "app/api";
import { useFromDate, useGrupp, useHamtstalle, useKund, useToDate } from "app/store";
import { useQuery } from "react-query";
import { getGrafAvfallstyperData } from "./charts";

export default function useGrafAvfallstyper() {
  const [toDate] = useToDate();
  const [fromDate] = useFromDate();
  const [selectedKund] = useKund();
  const [selectedGrupp] = useGrupp();

  const [selectedHamtstalle] = useHamtstalle();

  const hsnr = (selectedHamtstalle || { id: 0 }).id;

  const kundnr = selectedKund?.kundnr;

  const key = kundnr + "-" + hsnr + ":" + fromDate + ":" + toDate + ":" + selectedGrupp;

  const { data } = useQuery(["GrafAvfallstyper", key], () => {
    if (!kundnr) {
      return null;
    }

    return api.Statistik.GrafAvfallstyper(kundnr, hsnr, fromDate, toDate, selectedGrupp).then(
      getGrafAvfallstyperData
    );
  });

  return data;
}
