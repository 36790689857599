import React, { useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import InfoIcon from "@material-ui/icons/Info";
import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import Assignment from "@material-ui/icons/Assignment";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import EditIcon from "@material-ui/icons/Edit";
import api from "app/api";
import { useHamtstalle, useKund } from "app/store";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import MainForm from "views/Dashboard/MainForm";

const useStyles = makeStyles(styles);

// https://minasidor.smartrecycling.se/api/api/Profile/NastaFranHs/1945/1

// https://minasidor.smartrecycling.se/api/api/Profile/ForegaendeFranHs/1945/1

const userFormTypes = {
  operativt_ansvarig: "operativt_ansvarig",
  avtalsansvarig: "avtalsansvarig",
  fa_ansvarig: "fa_ansvarig",
};

export default function UserProfile() {
  const classes = useStyles();
  const [my, setMy] = React.useState({});
  const [info, setInfo] = React.useState({});
  const [email, setEmail] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [telefon, setTelefon] = React.useState("");
  const [portkod, setPortkod] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [loadingFa, setLoadingFa] = React.useState(false);
  const [loadingAvtal, setLoadingAvtal] = React.useState(false);

  const [successfullySaved, setSuccessfullySaved] = React.useState(false);

  const [opTel, setOpTel] = React.useState("");
  const [opNamn, setOpNamn] = React.useState("");
  const [opEmail, setOpEmail] = React.useState("");

  const [avtalNamn, setAvtalNamn] = React.useState("");
  const [avtalEmail, setAvtalEmail] = React.useState("");
  const [avtalTelefon, setAvtalTelefon] = React.useState("");

  const [faNamn, setFaNamn] = React.useState("");
  const [faEmail, setFaEmail] = React.useState("");
  const [faOrgnr, setFaOrgnr] = React.useState("");
  const [faTelefon, setFaTelefon] = React.useState("");
  const [faFtgnamn, setFaFtgnamn] = React.useState("");

  const [nvvrapport, setNvvrapport] = React.useState(false);

  const [selectedType, setSelectedType] = React.useState("");

  const [selectedKund] = useKund();
  const [selectedHamtstalle, setSelectedHamtstalle] = useHamtstalle();

  const [fields, setFields] = React.useState([
    { name: "Namn", fieldId: "opNamn", disabled: true, type: userFormTypes.operativt_ansvarig },
    { name: "Email", fieldId: "opEmail", disabled: true, type: userFormTypes.operativt_ansvarig },
    { name: "Telefon", fieldId: "opPhone", disabled: true, type: userFormTypes.operativt_ansvarig },
    {
      name: "Portkod",
      fieldId: "opPortCode",
      disabled: true,
      type: userFormTypes.operativt_ansvarig,
    },
    { name: "Namn", fieldId: "avtalNamn", disabled: true, type: userFormTypes.avtalsansvarig },
    { name: "Telefon", fieldId: "avtalPhone", disabled: true, type: userFormTypes.avtalsansvarig },
    { name: "Email", fieldId: "avtalEmail", disabled: true, type: userFormTypes.avtalsansvarig },
    { name: "Namn", fieldId: "faName", disabled: true, type: userFormTypes.fa_ansvarig },
    { name: "Email", fieldId: "faEmail", disabled: true, type: userFormTypes.fa_ansvarig },
    { name: "Telefon", fieldId: "faPhone", disabled: true, type: userFormTypes.fa_ansvarig },
  ]);

  let textInput = useRef(null);

  console.log("selectedHamtstalle: ", JSON.stringify(selectedHamtstalle));

  const sss = {
    location: "Vasagatan 46",
    email: "oki.simonsson@btf.se",
    phone: "0707909350",
    contact: "Oki Simonsson",
    keyCode: "PK 1658",
    id: 1,
  };

  console.log(!!sss);

  React.useEffect(() => {
    if (selectedKund) {
      api.Profile.My(selectedKund.kundnr)
        .then((res) => {
          setMy(res);
          setAvtalNamn(res.avtalNamn);
          setAvtalTelefon(res.avtalTelefon);
          setAvtalEmail(res.avtalEmail);
          setFaNamn(res.faNamn);
          setFaTelefon(res.faTelefon);
          setFaEmail(res.faEmail);
          setFaOrgnr(res.faOrgnr);
          setFaFtgnamn(res.faFtgnamn);
          setNvvrapport(res.nvvrapport);
        })
        .catch(console.log);
      api.Profile.Info(selectedKund.kundnr).then(setInfo).catch(console.log);
    }
  }, [selectedKund]);

  React.useEffect(() => {
    if (selectedHamtstalle) {
      setContact(selectedHamtstalle.contact);
      setEmail(selectedHamtstalle.email);
      setTelefon(selectedHamtstalle.phone);
      setPortkod(selectedHamtstalle.keyCode);
      setOpNamn(selectedHamtstalle.opNamn);
      setOpEmail(selectedHamtstalle.opEmail);
      setOpTel(selectedHamtstalle.opTel);
    }
  }, [selectedHamtstalle]);

  function reset() {
    setContact(selectedHamtstalle.contact);
    setEmail(selectedHamtstalle.email);
    setTelefon(selectedHamtstalle.phone);
    setPortkod(selectedHamtstalle.keyCode);
    setOpNamn(selectedHamtstalle.opNamn);
    setOpEmail(selectedHamtstalle.opEmail);
    setOpTel(selectedHamtstalle.opTel);
  }

  function resetAvtal() {
    api.Profile.My(selectedKund.kundnr)
      .then((res) => {
        console.log(res);
        setMy(res);
        setAvtalNamn(res.avtalNamn);
        setAvtalTelefon(res.avtalTelefon);
        setAvtalEmail(res.avtalEmail);
        setFaNamn(res.faNamn);
        setFaTelefon(res.faTelefon);
        setFaEmail(res.faEmail);
        setFaOrgnr(res.faOrgnr);
        setFaFtgnamn(res.faFtgnamn);
        setNvvrapport(res.nvvrapport);
      })
      .catch(console.log);
    api.Profile.Info(selectedKund.kundnr).then(setInfo).catch(console.log);
  }

  function save() {
    const data = {
      location: selectedHamtstalle.location,
      opNamn,
      opEmail,
      email,
      phone: telefon,
      contact,
      opTel,
      keyCode: portkod,
      id: selectedHamtstalle.id,
      cfarnr: selectedHamtstalle.cfarnr,
    };

    setLoading(true);
    api.Profile.Update(selectedKund.kundnr, data).finally(() => {
      setLoading(false);

      setSuccessfullySaved(true);
      setTimeout(() => {
        setSuccessfullySaved(false);
      }, 2000);

      setSelectedHamtstalle(data);

      setFields([...fields.map((field) => ({ ...field, disabled: true }))]);
    });
  }

  function saveAvtal() {
    const data = {
      location: selectedHamtstalle.location,
      avtalNamn,
      avtalEmail,
      avtalTelefon,
      id: selectedHamtstalle.id,
    };

    setLoadingAvtal(true);
    api.Profile.UpdateAvtal(selectedKund.kundnr, data).finally(() => {
      setLoadingAvtal(false);
    });
  }

  function saveFa() {
    const data = {
      location: selectedHamtstalle.location,
      faNamn,
      faEmail,
      faOrgnr,
      faFtgnamn,
      faTelefon,
      id: selectedHamtstalle.id,
      nvvrapport,
    };

    setLoadingFa(true);
    api.Profile.UpdateFA(selectedKund.kundnr, data).finally(() => {
      setLoadingFa(false);
    });
  }

  function editField(fieldId, type) {
    let newState = [...fields];

    if (type === userFormTypes.operativt_ansvarig) {
      reset();
    }

    if (type === userFormTypes.avtalsansvarig || type === userFormTypes.fa_ansvarig) {
      resetAvtal();
    }

    // set all to false except the one we want to edit
    newState = newState.map((f) => {
      if (f.fieldId === fieldId) {
        f.disabled = false;
        setSelectedType(f.type);
      } else {
        f.disabled = true;
      }
      return f;
    });

    // update textInput focus to the fieldId that was clicked on
    setFields([...newState]);
    textInput.current.focus();
  }

  return (
    <div>
      <MainForm hideDates />
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <GridContainer>
            <GridItem xs={12}>
              <Card className={classes.invoiceMarginTop}>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <Assignment />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}> Faktureringsuppgifter</h4>
                </CardHeader>
                <CardBody>
                  {my && (
                    <Table
                      tableHeaderColor="primary"
                      tableHead={["", ""]}
                      tableData={[
                        ["Adress", my.adress2],
                        ["Postnummer", my.postnr],
                        ["Postadress", my.postadress],
                        ["C/O", my.adress1],
                        ["Telefon", my.telefon],
                        ["Faktureringstyp", my.fakturaTyp],
                        [
                          my.fakturaTyp === "PDF-faktura" ? "PDF E-post" : "E-post",
                          my.fakturaTyp === "PDF-faktura" ? my.faktEmail : my.email,
                        ],
                        my.gln === "" ? ["GLN", my.gln] : null,
                        ["Referens", my.referens],
                        ["Organisationsnummer", my.orgnr],
                      ].filter((item) => item)}
                      coloredColls={[1]}
                      colorsColls={["primary"]}
                    />
                  )}
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <PermIdentity />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Operativt ansvarig</h4>
                </CardHeader>
                {selectedHamtstalle && (
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Namn"
                          id={fields.find((f) => f.fieldId === "opNamn").fieldId}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          disabled={fields.find((f) => f.fieldId === "opNamn").disabled}
                          endAdornment={
                            fields.find((f) => f.fieldId === "opNamn").disabled ? (
                              <EditIcon
                                onClick={() =>
                                  editField("opNamn", userFormTypes.operativt_ansvarig)
                                }
                              />
                            ) : null
                          }
                          inputProps={{
                            value: opNamn,
                            onChange: ({ target }) => {
                              setOpNamn(target.value);
                            },
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Telefon"
                          id={fields.find((f) => f.fieldId === "opPhone").fieldId}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          disabled={fields.find((f) => f.fieldId === "opPhone").disabled}
                          endAdornment={
                            fields.find((f) => f.fieldId === "opPhone").disabled ? (
                              <EditIcon
                                onClick={() =>
                                  editField("opPhone", userFormTypes.operativt_ansvarig)
                                }
                              />
                            ) : null
                          }
                          inputProps={{
                            value: opTel,
                            onChange: ({ target }) => {
                              setOpTel(target.value);
                            },
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <CustomInput
                          labelText="Email"
                          id={fields.find((f) => f.fieldId === "opEmail").fieldId}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputRef={textInput}
                          endAdornment={
                            fields.find((f) => f.fieldId === "opEmail").disabled ? (
                              <EditIcon
                                onClick={() =>
                                  editField("opEmail", userFormTypes.operativt_ansvarig)
                                }
                              />
                            ) : null
                          }
                          disabled={fields.find((f) => f.fieldId === "opEmail").disabled}
                          inputProps={{
                            value: opEmail,
                            onChange: ({ target }) => {
                              setOpEmail(target.value);
                            },
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Portkod"
                          id={fields.find((f) => f.fieldId === "opPortCode").fieldId}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          endAdornment={
                            fields.find((f) => f.fieldId === "opPortCode").disabled ? (
                              <EditIcon
                                onClick={() =>
                                  editField("opPortCode", userFormTypes.operativt_ansvarig)
                                }
                              />
                            ) : null
                          }
                          disabled={fields.find((f) => f.fieldId === "opPortCode").disabled}
                          inputProps={{
                            value: portkod,
                            onChange: ({ target }) => {
                              setPortkod(target.value);
                            },
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    {loading ? (
                      <CustomLinearProgress variant="indeterminate" color="primary" value={30} />
                    ) : (
                      // show button only if one field is not disabled
                      selectedType === userFormTypes.operativt_ansvarig &&
                      fields.find((f) => f.disabled === false) && (
                        <Button color="rose" className={classes.updateProfileButton} onClick={save}>
                          Uppdatera {fields.find((f) => !f.disabled)?.name}
                        </Button>
                      )
                    )}
                    {successfullySaved ? (
                      <SnackbarContent
                        message={
                          <span>
                            <b>Uppdatering lyckades!</b>
                          </span>
                        }
                        close
                        color="error"
                      />
                    ) : null}
                    <Clearfix />
                  </CardBody>
                )}
              </Card>
            </GridItem>

            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <PermIdentity />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Avtalsansvarig </h4>
                </CardHeader>
                {selectedHamtstalle && (
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Namn"
                          id={fields.find((f) => f.fieldId === "avtalNamn").fieldId}
                          endAdornment={
                            fields.find((f) => f.fieldId === "avtalNamn").disabled ? (
                              <EditIcon
                                onClick={() => editField("avtalNamn", userFormTypes.avtalsansvarig)}
                              />
                            ) : null
                          }
                          disabled={fields.find((f) => f.fieldId === "avtalNamn").disabled}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: avtalNamn,
                            onChange: ({ target }) => {
                              setAvtalNamn(target.value);
                            },
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Telefon"
                          id={fields.find((f) => f.fieldId === "avtalPhone").fieldId}
                          endAdornment={
                            fields.find((f) => f.fieldId === "avtalPhone").disabled ? (
                              <EditIcon
                                onClick={() =>
                                  editField("avtalPhone", userFormTypes.avtalsansvarig)
                                }
                              />
                            ) : null
                          }
                          disabled={fields.find((f) => f.fieldId === "avtalPhone").disabled}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: avtalTelefon,
                            onChange: ({ target }) => {
                              setAvtalTelefon(target.value);
                            },
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="E-post"
                          id={fields.find((f) => f.fieldId === "avtalEmail").fieldId}
                          endAdornment={
                            fields.find((f) => f.fieldId === "avtalEmail").disabled ? (
                              <EditIcon
                                onClick={() =>
                                  editField("avtalEmail", userFormTypes.avtalsansvarig)
                                }
                              />
                            ) : null
                          }
                          disabled={fields.find((f) => f.fieldId === "avtalEmail").disabled}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: avtalEmail,
                            onChange: ({ target }) => {
                              setAvtalEmail(target.value);
                            },
                          }}
                        />
                      </GridItem>
                    </GridContainer>

                    {loadingAvtal ? (
                      <CustomLinearProgress variant="indeterminate" color="primary" value={30} />
                    ) : (
                      // show button only if one field is not disabled
                      selectedType === userFormTypes.avtalsansvarig &&
                      fields.find((f) => f.disabled === false) && (
                        <Button
                          color="rose"
                          className={classes.updateProfileButton}
                          onClick={saveAvtal}>
                          Uppdatera {fields.find((f) => !f.disabled)?.name}
                        </Button>
                      )
                    )}
                    {successfullySaved ? (
                      <SnackbarContent
                        message={
                          <span>
                            <b>Uppdatering lyckades!</b>
                          </span>
                        }
                        close
                        color="error"
                      />
                    ) : null}
                    <Clearfix />
                  </CardBody>
                )}
              </Card>
            </GridItem>

            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <PermIdentity />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>FA-ansvarig</h4>

                  <div>
                    <InfoIcon />
                  </div>
                  <p style={{ padding: 10, borderRadius: 10, backgroundColor: "#999" }}>
                    Er kontakt för transporter innehållande farligt avfall i samband med
                    anteckningtill Naturvårdsverket via Smart Recycling. En kontaktperson behöver
                    anges oavsett om Smart Recycling agerar ombud eller inte.{" "}
                  </p>
                </CardHeader>

                {selectedHamtstalle && (
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Namn"
                          id={fields.find((f) => f.fieldId === "faName").fieldId}
                          endAdornment={
                            fields.find((f) => f.fieldId === "faName").disabled ? (
                              <EditIcon
                                onClick={() => editField("faName", userFormTypes.fa_ansvarig)}
                              />
                            ) : null
                          }
                          disabled={fields.find((f) => f.fieldId === "faName").disabled}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: faNamn,
                            onChange: ({ target }) => {
                              setFaNamn(target.value);
                            },
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Telefon"
                          id={fields.find((f) => f.fieldId === "faPhone").fieldId}
                          endAdornment={
                            fields.find((f) => f.fieldId === "faPhone").disabled ? (
                              <EditIcon
                                onClick={() => editField("faPhone", userFormTypes.fa_ansvarig)}
                              />
                            ) : null
                          }
                          disabled={fields.find((f) => f.fieldId === "faPhone").disabled}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: faTelefon,
                            onChange: ({ target }) => {
                              setFaTelefon(target.value);
                            },
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Email"
                          id={fields.find((f) => f.fieldId === "faEmail").fieldId}
                          endAdornment={
                            fields.find((f) => f.fieldId === "faEmail").disabled ? (
                              <EditIcon
                                onClick={() => editField("faEmail", userFormTypes.fa_ansvarig)}
                              />
                            ) : null
                          }
                          disabled={fields.find((f) => f.fieldId === "faEmail").disabled}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: faEmail,
                            onChange: ({ target }) => {
                              setFaEmail(target.value);
                            },
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12} style={{ paddingTop: 118 }}>
                        <label style={{ fontSize: 11, paddingTop: "100px" }}>
                          Smart Recycling agerar ombud
                        </label>
                        <div
                          style={{
                            paddingTop: 5,
                            paddingBottom: 5,
                            marginBottom: 5,
                            borderBottom: "1px solid #d2d2d2",
                          }}>
                          {nvvrapport ? "Aktiverat" : "Inaktiverat"}
                        </div>
                      </GridItem>
                    </GridContainer>

                    {loadingFa ? (
                      <CustomLinearProgress variant="indeterminate" color="primary" value={30} />
                    ) : (
                      // show button only if one field is not disabled
                      selectedType === userFormTypes.fa_ansvarig &&
                      fields.find((f) => f.disabled === false) && (
                        <Button
                          color="rose"
                          className={classes.updateProfileButton}
                          onClick={saveFa}>
                          Uppdatera {fields.find((f) => !f.disabled)?.name}
                        </Button>
                      )
                    )}
                    {successfullySaved ? (
                      <SnackbarContent
                        message={
                          <span>
                            <b>Uppdatering lyckades!</b>
                          </span>
                        }
                        close
                        color="error"
                      />
                    ) : null}
                    <Clearfix />
                  </CardBody>
                )}
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>

        {info != null ?? (
          <GridItem xs={12} sm={12} md={4}>
            <Card profile className={classes.infoMarginTop}>
              {info.imageSrc != null ? (
                <CardAvatar profile>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      src={`http://minasidor.smartrecycling.se/assets/images/profile/${info.imageSrc}`}
                      alt="..."
                    />
                  </a>
                </CardAvatar>
              ) : (
                <div></div>
              )}
              <CardBody profile>
                <h4 className={classes.cardTitle}>{info.saljarNamn}</h4>
                <p className={classes.description}>{info.saljarEmail}</p>
                <h6 className={classes.cardCategory}>{info.saljarTelefon}</h6>
              </CardBody>
            </Card>

            <Card profile>
              <CardBody profile>
                <h6 className={classes.cardCategory}>Kundtjänst:</h6>
                <p className={classes.description}>info@smartrecycling.se</p>
                <h6 className={classes.cardCategory}>08-56211811</h6>
              </CardBody>
            </Card>

            <Card profile>
              <CardBody profile>
                <h6 className={classes.cardCategory}>Ekonomi:</h6>
                <p className={classes.description}>ekonomi@smartrecycling.se</p>
              </CardBody>
            </Card>
          </GridItem>
        )}
      </GridContainer>
    </div>
  );
}
