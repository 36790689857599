// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import { onlyUnique, sortNumbers } from "app/util";
import Chartjs from "chart.js";
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import colors from "./colors";
import months from "./months";
import React from "react";
import { useSelectedGraphs } from "app/store";

// eslint-disable-next-line no-unused-vars
Chartjs.plugins.unregister(ChartDataLabels);

export const round = (num) => Math.round(num * 10) / 10;

const checkBoxStyles = () => ({
  root: {
    "&$checked": {
      color: "#F44435",
    },
  },
  checked: {},
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

export const graphTypes = {
  small: "small",
  regular: "regular",
  large: "large",
};

export const pieProps = {
  legend: {
    display: true,
    reverse: false,
    fullWidth: false,
    position: "bottom",
    labels: {
      boxWidth: 13,
    },
  },
  redraw: false,
};

export const chartProps = {
  legend: {
    display: true,
    reverse: false,
    fullWidth: false,
    position: "bottom",
    labels: {
      boxWidth: 13,
    },
  },
  redraw: false,
  options: {
    responsive: true,

    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            fontSize: 14,
            fontStyle: "bold",
            beginAtZero: true,
          },
        },
      ],
    },
  },
};

export const chartPropsLabels = { ...chartProps, plugins: [ChartDataLabels] };

export function getGrafAvfallstyperData(data) {
  return {
    labels: data.labels,
    datasets: data.series
      .map((item, i) => {
        item = item.replace("m3", "Kg");
        return {
          fill: false,
          label: item,
          lineTension: 0,
          borderCapStyle: "square",
          borderJoinStyle: "miter",
          borderColor: colors[i],
          backgroundColor: colors[i] + "33",
          data: data.values[i],
        };
      })
      .filter((item) => item && !item.label.includes("(st)"))
      .filter((item) => item && !item.label.includes("Övrigt"))
      .filter((item) => item && !item.label.includes("Löst avfall (Kg)")),
  };
}

export function getFordelningAtervinningData(data) {
  const labelMap = {
    AA: "Återanvändning",
    FA: "Farligt avfall",
    EA: "Energiåtervinning",
    MA: "Materialåtervinning",
  };

  const labels = Object.keys(labelMap);

  const map = {
    AA: 0,
    FA: 0,
    EA: 0,
    MA: 0,
  };

  data.forEach((item) => {
    map[item.beskrivning] = map[item.beskrivning] || 0;
    map[item.beskrivning] += round(item.antal);
  });

  return {
    labels: labels.map((label) => labelMap[label] || label),
    datasets: [
      {
        data: labels.map((beskrivning) => map[beskrivning]),
        backgroundColor: ["#073e5d", "#5b538f", "#bf5092", "#ec6667"],
        hoverBackgroundColor: ["#808b9f", "#a8a2c6", "#dcaecb", "#f7b8af"],
      },
    ],
  };
}

export function getCo2MaterialData(data) {
  const materials = data.map((item) => item.beskrivning).filter(onlyUnique);
  const labels = data.map((item) => `${item.year}-${item.month}`).filter(onlyUnique);

  const map = {};

  data.forEach((item) => {
    const date = `${item.year}-${item.month}`;
    map[item.beskrivning] = map[item.beskrivning] || {};
    map[item.beskrivning][date] = map[item.beskrivning][date] || {};
    map[item.beskrivning][date] = round(item.antal);
  });

  return {
    labels,
    datasets: materials.map((label, i) => {
      return {
        label,
        fill: true,
        borderColor: colors[i],
        backgroundColor: colors[i],
        data: labels.map((date) => round(map[label][date])),
      };
    }),
  };
}

export function getCo2MaterialPerMonthData(data) {
  const years = data.map((item) => item.year).filter(onlyUnique);
  const monthNumbers = sortNumbers(data.map((item) => parseInt(item.month))).filter(onlyUnique);

  const map = {};

  data.forEach((item) => {
    map[item.month] = map[item.month] || {};
    map[item.month][item.year] = map[item.month][item.year] || 0;
    map[item.month][item.year] += item.antal;
  });

  return {
    labels: years,
    datasets: monthNumbers.map((m, i) => {
      return {
        fill: true,
        label: months[m],
        borderColor: colors[i],
        backgroundColor: colors[i],
        data: years.map((year) => round(map[m][year])),
      };
    }),
  };
}

export function getBesparingCO2KorstrackaData(data) {
  const years = data.map((item) => item.year).filter(onlyUnique);
  const monthNumbers = sortNumbers(data.map((item) => parseInt(item.month))).filter(onlyUnique);

  const map = {};

  data.forEach((item) => {
    map[item.month] = map[item.month] || {};
    map[item.month][item.year] = map[item.month][item.year] || {};
    map[item.month][item.year] = item.antal;
  });

  return {
    labels: years,
    datasets: monthNumbers.map((m, i) => {
      return {
        fill: true,
        label: months[m],
        borderColor: colors[i],
        backgroundColor: colors[i],
        data: years.map((year) => (map[m] ? round(map[m][year]) : 0)),
      };
    }),
  };
}

const label = { inputProps: { "aria-label": "Checkbox" } };

export const Graph = ({
  id,
  title,
  tooltip,
  tooltipId,
  children,
  useSelect = false,
  isSelected = false,
  type = graphTypes.regular,
}) => {
  const { addToSelectedGraphs } = useSelectedGraphs();
  const useStyles = makeStyles((theme) => {
    return {
      cardTitle: {
        [theme.breakpoints.down("md")]: {
          fontSize: 12,
        },
      },
    };
  });

  const classes = useStyles();

  return (
    <div className={classes.graph}>
      <Card chart>
        <CardHeader>
          <GridContainer
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}>
            <GridItem xs={8} sm={8} md={8}>
              {type === graphTypes.small ? (
                <h5 className={classes.cardTitle}>{title}</h5>
              ) : (
                <h5 className={classes.cardTitle}>{title}</h5>
              )}
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  textAlign: "end",
                }}>
                {useSelect && (
                  <>
                    <CustomCheckbox
                      checked={isSelected}
                      onClick={() => addToSelectedGraphs(id)}
                      {...label}
                    />
                  </>
                )}

                {tooltip != null ? <InfoIcon data-tip data-for={tooltipId} /> : <></>}
              </div>
            </GridItem>
          </GridContainer>

          {tooltip}
        </CardHeader>
        <CardBody className={classes.graph}>{children}</CardBody>
      </Card>
    </div>
  );
};
