// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import "chartjs-plugin-datalabels";
// core components
import { Button } from "@material-ui/core";
import api from "app/api";
import { useKund } from "app/store";
import Card from "components/Card/Card";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import ArticleDialog from "./ArticleDialog";
import MainForm from "./MainForm";
import { Graph, chartProps, pieProps } from "./charts";
import { generatePdf } from "./pdf";
import useBesparingCO2Korstracka from "./useBesparingCO2Korstracka";
import useBesparingCO2Total from "./useBesparingCO2Total";
import useCo2Material from "./useCo2Material";
import useFordelningAtervinning from "./useFordelningAtervinning";
import useGrafAvfallstyper from "./useGrafAvfallstyper";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();

  const ref1 = React.useRef();
  const ref2 = React.useRef();
  const ref3 = React.useRef();
  const ref4 = React.useRef();
  const ref5 = React.useRef();
  const ref6 = React.useRef();

  const [article, setArticle] = React.useState(null);

  const besparingCO2Korstracka = useBesparingCO2Korstracka();
  const besparingCO2Total = useBesparingCO2Total();
  const { co2Material, co2MaterialPerMonth } = useCo2Material();
  const fordelningAtervinning = useFordelningAtervinning();
  const grafAvfallstyper = useGrafAvfallstyper();

  const [selectedKund] = useKund();

  const [posts, setPosts] = React.useState([]);

  React.useEffect(() => {
    api.News.posts().then(setPosts).catch(console.log);
  }, []);

  return (
    <div>
      <MainForm
        showShortCutIntervals
        hideButtons
        isDashboard
        generatePdf={() => {
          generatePdf({
            ref1,
            ref2,
            ref3,
            ref4,
            ref5,
            ref6,
            name: selectedKund.namn,
          });
        }}
      />

      <br />

      {posts.slice(0, 3).length > 0 && (
        <>
          <GridContainer style={{ border: "0px solid red" }}>
            <GridItem xs={6} sm={6} md={6}>
              <h2 style={{ marginBottom: -8, paddingLeft: 8 }}>Nyheter</h2>
            </GridItem>
            <GridItem xs={6} sm={6} md={6} style={{ textAlign: "right" }}>
              <NavLink to="/admin/news">
                <Button style={{ color: "#F44435", marginTop: 38 }} variant="text">
                  Visa alla nyheter
                </Button>
              </NavLink>
            </GridItem>
          </GridContainer>

          <GridContainer>
            {posts.slice(0, 3).map((post) => (
              <GridItem xs={4} sm={4} md={4} key={post.id}>
                <Card style={{ padding: 16, cursor: "pointer" }} onClick={() => setArticle(post)}>
                  <h4 style={{ minHeight: 50 }}>{post.title.rendered}</h4>
                  <img
                    alt=""
                    style={{
                      width: "100%",
                      height: 220,
                      objectFit: "cover",
                      objectPosition: "center",
                      marginBottom: 16,
                    }}
                    src={post.yoast_head_json.og_image[0].url}
                  />
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2, // Number of lines to show
                      height: 40,
                    }}
                    dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
                  />
                </Card>
              </GridItem>
            ))}
          </GridContainer>
        </>
      )}

      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Graph
            title="Hämtat material per materialslag och kilo"
            {...{ classes }}
            tooltipId="info1"
            tooltip={
              <ReactTooltip id="info1">
                <div style={{ maxWidth: 400 }}>
                  <strong>Vad grafen redovisar</strong>
                  <p>
                    Grafen redovisar hur mycket material vi har hämtat. Materialet är uppdelat per
                    materialslag och fördelning per månad.
                  </p>
                  <strong>Så här har vi räknat</strong>

                  <p>
                    En del material är beräknat på schablonvärde vilket är vanligt förekommande vid
                    viktberäkning. Det är en snittvikt för tusentals hämtade kärl från samtliga
                    kunder där vi på så sätt kan räkna ut vikt för både material och kärl. Denna
                    metod används i hela branschen då det inte finns möjlighet att väga kärl, säckar
                    eller behållare separat. Undantaget är vägning av farligt avfall som sker enligt
                    lagstiftning.
                  </p>
                </div>
              </ReactTooltip>
            }>
            {grafAvfallstyper && <Line ref={ref1} {...chartProps} data={grafAvfallstyper} />}
          </Graph>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Graph
            title="Fördelning hämtat material per kategori och i Kilo"
            {...{ classes }}
            tooltipId="info2"
            tooltip={
              <ReactTooltip id="info2">
                <div style={{ maxWidth: 400 }}>
                  <strong>Vad grafen redovisar</strong>
                  <p>
                    Grafen redovisar vad som sker med det material vi hämtat. Det är en fördelning
                    av vad som går till energi- eller materialåtervinning, vad som är farligt
                    avfall, vad som återbrukas samt vad som läggs på deponi.
                  </p>
                  <strong>Så här har vi räknat</strong>
                  <p>
                    Allt material som vi hämtat går till en av de nämnda processerna ovan. Här
                    redovisas fördelningen av det material som vi hämtat specifikt från er.
                  </p>
                </div>
              </ReactTooltip>
            }>
            {fordelningAtervinning && (
              <Doughnut ref={ref2} data={fordelningAtervinning} {...pieProps} />
            )}
          </Graph>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Graph
            title="Besparat kilo CO2 per materialslag genom återvinning framför nyproduktion"
            {...{ classes }}
            tooltipId="info3"
            tooltip={
              <ReactTooltip id="info3">
                <div style={{ maxWidth: 400 }}>
                  <strong>Vad grafen redovisar</strong>
                  <p>
                    Grafen redovisar besparat CO2-utsläpp per materialslag från er verksamhets
                    återvinning jämfört med om ni inte hade sorterat, återvunnit eller återbrukat.
                    Det är besparingen av CO2 som utvinns genom att processa material i stället för
                    att producera nytt råmaterial av samma materialslag.
                  </p>
                  <strong>Så här har vi räknat</strong>
                  <p>
                    Beräkningarna baseras på besparat CO2-utsläpp genom materialåtervinning eller
                    återbruk jämfört med nyproduktion på råmaterial. Siffrorna redovisas utifrån
                    materialnivå och kommer från Återvinningsindustrierna samt IVL Svenska
                    Miljöinstitutet.
                  </p>
                </div>
              </ReactTooltip>
            }>
            {co2Material && <Bar ref={ref3} data={co2Material} {...chartProps} />}
          </Graph>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Graph
            title="Besparat kilo CO2 per månad genom återvinning"
            {...{ classes }}
            tooltipId="info4"
            tooltip={
              <ReactTooltip id="info4">
                <div style={{ maxWidth: 400 }}>
                  <strong>Vad grafen redovisar</strong>
                  <p>
                    Grafen redovisar besparat CO2-utsläpp per månad från er verksamhets återvinning
                    jämfört med om ni inte hade sorterat, återvunnit eller återbrukat. Det är
                    besparingen av CO2 som utvinns genom att processa material i stället för att
                    producera nytt råmaterial av samma materialslag.
                  </p>
                  <strong>Så här har vi räknat</strong>
                  <p>
                    Beräkningarna baseras på besparat CO2-utsläpp genom materialåtervinning eller
                    återbruk jämfört med nyproduktion på råmaterial. Siffrorna redovisas utifrån
                    materialnivå och kommer från Återvinningsindustrierna samt IVL Svenska
                    Miljöinstitutet.
                  </p>
                </div>
              </ReactTooltip>
            }>
            {co2MaterialPerMonth && <Bar ref={ref4} data={co2MaterialPerMonth} {...chartProps} />}
          </Graph>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Graph
            title="Besparat Kilo CO2 genom fossilfria transport istället för en Dieseldriven motsvarighet"
            {...{ classes }}
            tooltipId="info5"
            tooltip={
              <ReactTooltip id="info5">
                <div style={{ maxWidth: 400 }}>
                  <strong>Vad grafen redovisar</strong>
                  <p>
                    Grafen redovisar besparat CO2-utsläpp genom valet av oss som fossilfri
                    leverantör i stället för en dieselberoende leverantör. Siffrorna jämförs utifrån
                    distansen mellan vår anläggning och era hämtningsplatser med ett likadant fordon
                    som kör på diesel.
                  </p>
                  <strong>Så här har vi räknat</strong>
                  <p>
                    Beräkningarna baseras på besparat CO2-utsläpp per körd kilometer, mellan fordon
                    körda på 100 procent biogas jämfört med diesel. Vi beräknar utsläppen av vår
                    egen biogas-lastbil och jämför siffrorna med en likvärdig diesel-lastbil.
                    Siffrorna är hämtade från Eon, IVL Svenska Miljöinstitutet samt IVECO.
                  </p>
                </div>
              </ReactTooltip>
            }>
            {besparingCO2Korstracka && (
              <Bar ref={ref5} data={besparingCO2Korstracka} {...chartProps} />
            )}
          </Graph>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Graph
            title="Total Besparat Co2 per månad (återvinning och transporter)"
            {...{ classes }}
            tooltipId="info6"
            tooltip={
              <ReactTooltip id="info6">
                <div style={{ maxWidth: 400 }}>
                  <strong>Vad grafen redovisar</strong>
                  <p>
                    Grafen redovisar besparat CO2-utsläpp per månad genom materialåtervinning eller
                    återbruk jämfört med nyproduktion på råmaterial samt valet av oss som fossilfri
                    leverantör i stället för en dieselberoende leverantör.
                  </p>
                  <strong>Så här har vi räknat</strong>
                  <p>
                    Beräkningarna baseras på besparat CO2-utsläpp genom materialåtervinning eller
                    återbruk jämfört med nyproduktion på råmaterial. Siffrorna redovisas utifrån
                    materialnivå och kommer från Återvinningsindustrierna samt IVL Svenska
                    Miljöinstitutet. Dessa siffror adderas till beräkningarna baserade på besparat
                    CO2-utsläpp per körd kilometer, mellan fordon körda på 100 procent biogas
                    jämfört med diesel. Vi beräknar utsläppen av vår egen biogas-lastbil och jämför
                    siffrorna med en likvärdig diesel-lastbil. Siffrorna är hämtade från Eon, IVL
                    Svenska Miljöinstitutet samt IVECO.
                  </p>
                </div>
              </ReactTooltip>
            }>
            {besparingCO2Total && <Bar ref={ref6} data={besparingCO2Total} {...chartProps} />}
          </Graph>
        </GridItem>
      </GridContainer>
      <br />
      {article ? (
        <ArticleDialog {...{ article }} onClose={() => setArticle(null)} classes={{}} />
      ) : null}
    </div>
  );
}
