import esmartGreen from "assets/img/esmart-green";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
const a4 = { width: 210, height: 297 };

export function format(num) {
  return (Math.round((num + Number.EPSILON) * 10) / 10).toFixed(1);
}

export async function generatePdf({ title, events }) {
  const doc = new jsPDF("p", "mm", "a4", true);

  doc.setTextColor("#77817B");
  doc.setFillColor("#77817B");

  const factor = 20;

  // doc.rect(0, 0, 210, 80, 'F');

  let lastRows = 0;

  let y = 0;
  let page = 1;
  events.forEach((event, index) => {
    if (index < 100) {
      // let y = index % 15;

      const height = 55 + y * 15 + lastRows * 5 + 15 + event.orderrader.length * 5;
      if (height > 297 - 20) {
        y = 0;
        lastRows = 0;
      }

      if (lastRows === 0 && index > 0) {
        doc.addPage();
        page++;
      }

      if (lastRows === 0) {
        doc.setFontSize(12);

        doc.text(page + " ", 100 + 0 * 30, 65 + 15 * 15);

        doc.addImage(
          esmartGreen,
          "PNG",
          a4.width - 820 / factor - 10,
          10,
          820 / factor,
          390 / factor
        );

        pageTitle(title, 13, 23, doc);
        doc.rect(10, 55 - 15, 190, 15);
        doc.setFontSize(12);

        doc.text("Datum", 15 + 0 * 30, 65 - 15);
        doc.text("Vecka", 15 + 1 * 30, 65 - 15);
        doc.text("Veckodag", 15 + 2 * 30, 65 - 15);
        doc.text("Beskrivning", 15 + 3 * 30, 65 - 15);
      }

      doc.rect(10, 55 + y * 15 + lastRows * 5, 190, 15 + event.orderrader.length * 5);
      doc.setFontSize(10);

      doc.text(event.start.substring(0, 10), 15 + 0 * 30, 60 + y * 15 + lastRows * 5);
      doc.text(event.vecka + " ", 15 + 1 * 30, 60 + y * 15 + lastRows * 5);
      doc.text(event.veckodag, 15 + 2 * 30, 60 + y * 15 + lastRows * 5);
      doc.text(event.title, 15 + 3 * 30, 60 + y * 15 + lastRows * 5);

      // Beskrivning
      // Typ
      // Antal

      event.orderrader.forEach((row, idx) => {
        doc.text(row.beskrivninG1 + "", 15 + 3 * 30, 65 + y * 15 + idx * 5 + 5 + lastRows * 5);
        doc.text(row.beskrivninG2 + "", 15 + 3 * 30 + 60, 65 + y * 15 + idx * 5 + 5 + lastRows * 5);
        doc.text(row.antal + "", 15 + 3 * 30 + 90, 65 + y * 15 + idx * 5 + 5 + lastRows * 5);
      });

      lastRows += event.orderrader.length;
      y++;
    }
  });

  doc.save(title + ".pdf");
  return true;
}

// eslint-disable-next-line no-unused-vars
function grid(doc) {
  doc.setDrawColor("#e7e7e7");

  for (let i = 0; i < 21; i++) {
    doc.line(i * 10, 0, i * 10, 297);
  }

  for (let j = 0; j < 30; j++) {
    doc.line(0, j * 10, 210, j * 10);
  }
}

function pageTitle(text, x, y, doc, fontSize) {
  doc.setFont("helvetica", "bold");
  doc.setFontSize(fontSize ?? 20);
  doc.text(text, x, y);
}

(function (API) {
  API.textCenter = function (txt, options, x, y) {
    options = options || {};
    /* Use the options align property to specify desired text alignment
     * Param x will be ignored if desired text alignment is 'center'.
     * Usage of options can easily extend the function to apply different text
     * styles and sizes
     */
    if (options.align === "center") {
      // Get current font size
      var fontSize = this.internal.getFontSize();

      // Get page width
      var pageWidth = this.internal.pageSize.width;

      // Get the actual text's width
      /* You multiply the unit width of your string by your font size and divide
       * by the internal scale factor. The division is necessary
       * for the case where you use units other than 'pt' in the constructor
       * of jsPDF.
       */
      const txtWidth = (this.getStringUnitWidth(txt) * fontSize) / this.internal.scaleFactor;

      // Calculate text's x coordinate
      x = (pageWidth - txtWidth) / 2;
    }

    // Draw text at x,y
    this.text(txt, x, y);
  };
})(jsPDF.API);

var splitRegex = /\r\n|\r|\n/g;
jsPDF.API.textRight = function (text, x, y, hAlign, vAlign) {
  var fontSize = this.internal.getFontSize() / this.internal.scaleFactor;

  // As defined in jsPDF source code
  var lineHeightProportion = 1.15;

  var splittedText = null;
  var lineCount = 1;
  if (vAlign === "middle" || vAlign === "bottom" || hAlign === "center" || hAlign === "right") {
    splittedText = typeof text === "string" ? text.split(splitRegex) : text;

    lineCount = splittedText.length || 1;
  }

  // Align the top
  y += fontSize * (2 - lineHeightProportion);

  if (vAlign === "middle") y -= (lineCount / 2) * fontSize;
  else if (vAlign === "bottom") y -= lineCount * fontSize;

  if (hAlign === "center" || hAlign === "right") {
    var alignSize = fontSize;
    if (hAlign === "center") alignSize *= 0.5;

    if (lineCount > 1) {
      for (var iLine = 0; iLine < splittedText.length; iLine++) {
        this.text(
          splittedText[iLine],
          x - this.getStringUnitWidth(splittedText[iLine]) * alignSize,
          y
        );
        y += fontSize * lineHeightProportion;
      }
      return this;
    }
    x -= this.getStringUnitWidth(text) * alignSize;
  }

  this.text(text, x, y);
  return this;
};
