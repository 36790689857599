import React from "react";
import { getCo2MaterialData, getCo2MaterialPerMonthData } from "./charts";
import useBesparingCO2 from "./useBesparingCO2";

export default function useCo2Material() {
  const besparingCO2 = useBesparingCO2();
  const [co2Material, setCo2Material] = React.useState(null);
  const [co2MaterialPerMonth, setCo2MaterialPerMonth] = React.useState(null);

  React.useEffect(() => {
    if (besparingCO2) {
      setCo2Material(getCo2MaterialData(besparingCO2));
      setCo2MaterialPerMonth(getCo2MaterialPerMonthData(besparingCO2));
    }
  }, [besparingCO2]);

  return { co2Material, co2MaterialPerMonth };
}
