import { atom, useAtom } from "jotai";

const loadingAtom = atom(true);

export function useLoading() {
  return useAtom(loadingAtom);
}

export const Store = {
  token: "",
  get: (key) => {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.log({ e });
    }

    return null;
  },
  put: (key, value) => localStorage.setItem(key, JSON.stringify(value)),
};

const usernameAtom = atom(Store.get("username"));

export function useUsername() {
  const [username, setUsername_] = useAtom(usernameAtom);

  function setUsername(username) {
    setUsername_(username);
    Store.put("username", username);
  }

  return [username, setUsername];
}

export function useIsLoggedIn() {
  const [username] = useUsername();

  return localStorage.getItem("token") && username;
}
