/* eslint-disable no-useless-escape */
import { TextField } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import React from "react";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
// Regex for one or more (comma separated) email addresses.
const regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})(?:,\s*([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}))*$/;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paddingTop: {
    paddingTop: "4px",
  },
}));

export default function EmailReportModal({ show, onClose, handleSend }) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [userInput, setUserInput] = React.useState("");
  const [inputError, setInputError] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [sendError, setSendError] = React.useState(false);

  const handleClose = () => {
    setDisabled(false);
    setSuccess(false);
    setSendError(false);
    setInputError(false);
    setUserInput("");
    onClose();
  };

  const onSend = async () => {
    if (regex.test(userInput)) {
      setSendError(false);
      setDisabled(true);
      inputError && setInputError(false);
      //make array of unique email dresses in the userInput string
      const emails = userInput
        .split(",")
        .map((email) => email.trim())
        .filter((email, index, self) => self.indexOf(email) === index);

      const result = await handleSend(emails);
      setDisabled(false);
      if (result === true) {
        setSuccess(true);
      } else {
        setSendError(true);
      }
    } else {
      setInputError(true);
    }
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h3 id="simple-modal-title">Skicka med email</h3>
      <TextField
        error={inputError}
        helperText={
          inputError
            ? "Ogiltig e-postadress(er). Om du har angett flera e-postadresser, kontrollera att de är separerade med ett kommatecken."
            : ""
        }
        onChange={(e) => setUserInput(e.target.value)}
        id="outlined-basic"
        label="Email"
        fullWidth
        variant="outlined"
      />
      <p id="simple-modal-description" className={classes.paddingTop}>
        För att ange flera e-postadresser, separera dem med kommatecken.
      </p>
      {disabled && <p>Skickar...</p>}
      {success && <p>Skickat!</p>}
      {sendError && <p>Det gick inte att skicka.</p>}
      <Button
        color="primary"
        onClick={success ? () => handleClose() : () => onSend()}
        disabled={disabled}>
        {success ? "Stäng" : "Skicka"}
      </Button>
    </div>
  );

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      {body}
    </Modal>
  );
}
