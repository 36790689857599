export default [
  "#ec6667",

  "#bf5092",

  "#5b538f",

  "#616161",

  "#073e5d",

  "#f5a31b",

  "#f7b8af",

  "#dcaecb",

  "#a8a2c6",
  "#a8a6a7",

  "#808b9f",

  "#fdd397",

  "#FC6E51",
  "#A0D468",
  "#FFCE54",
  "#48CFAD",
  "#EC87C0",
  "#4FC1E9",
  "#AC92EC",
  "#5D9CEC",
  "#cccccc",
  "#ED5565",

  // apple
  "#FF453A",
  "#FF9F0A",
  "#FFD60A",
  "#32D74B",
  "#64D2FF",
  "#0A84FF",
  "#5E5CE6",
  "#BF5AF2",
  "#FF375F",
  "#8E8E93",

  "#FF453A",
  "#FF9F0A",
  "#FFD60A",
  "#32D74B",
  "#64D2FF",
  "#0A84FF",
  "#5E5CE6",
  "#BF5AF2",
  "#FF375F",
  "#8E8E93",
];
