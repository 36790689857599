const language = "en";

const lables = {
  login: {
    en: "Login",
    sv: "",
  },
};

export default function (label) {
  if (label && lables[label] && lables[label][language]) {
    return lables[label][language];
  }

  return label;
}
