import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { useGrupper } from "app/store";
import SelectFastighet from "./ui/SelectFastighet";
import SelectGrupp from "./ui/SelectGrupp";
import SelectHamtstalle from "./ui/SelectHamtstalle";
import SelectKund from "./ui/SelectKund";
import React from "react";

export default function ParamsForm() {
  const grupper = useGrupper();

  return (
    <div>
      <GridContainer justify="center">
        {grupper && grupper.length > 2 && (
          <Item>
            <SelectGrupp />
          </Item>
        )}

        <Item>
          <SelectKund />
        </Item>
        <Item>
          <SelectFastighet />
        </Item>
        <Item>
          <SelectHamtstalle />
        </Item>
      </GridContainer>
    </div>
  );
}

function Item(props) {
  return (
    <GridItem
      xs={12}
      sm={6}
      md={6}
      lg={3}
      style={{ marginTop: "10px", marginBottom: "10px" }}
      {...props}
    />
  );
}
