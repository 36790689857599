import { useHamtstalle, useHamtstallen } from "app/store";
import React from "react";
import SelectControl from "../SelectControl";
import { useFastighet } from "app/store";
import { useFastigheter } from "app/store";
import { useKunder } from "app/store";
import { useKund } from "app/store";
import { useGrupp } from "app/store";
import { DEFAULT_FASTIGHET } from "app/store";

export default function SelectHamtstalle() {
  const kunder = useKunder();
  const [kund, setKund] = useKund();
  const [, setGrupp] = useGrupp();
  const hamtstallen = useHamtstallen();
  const fastigheter = useFastigheter();
  const [, setFastighet] = useFastighet();

  const [hamtstalle, setHamtstalle] = useHamtstalle();

  return (
    <SelectControl
      value={hamtstalle}
      options={hamtstallen}
      disableClearable
      id="hamtstalle-select"
      label="Välj hämtställe"
      getOptionSelected={(option, value) => value && value.id === option.id}
      getOptionLabel={(option) => (option ? option.location : "")}
      onChange={
        hamtstallen.length > 1
          ? (_, newValue) => {
              setHamtstalle(newValue);
              if (newValue) {
                const fastighet = fastigheter.find(
                  (item) => item.namn.indexOf(newValue.fastbet) !== -1
                );

                if (fastighet) {
                  if (fastigheter.length > 1) {
                    setFastighet(fastighet);
                  }

                  if (fastighet.namn !== "Alla") {
                    const kundnr = fastighet.namn.split("(").pop().split(")")[0];

                    // eslint-disable-next-line eqeqeq
                    const newKund = kunder.find((k) => k.kundnr == kundnr);

                    const readonly = kund && kund.readonly;
                    if (!readonly && newKund) {
                      setKund(newKund);
                      setGrupp(newKund.kundgrupp);
                    }
                  }
                } else {
                  if (fastigheter.length > 1) {
                    setFastighet(DEFAULT_FASTIGHET);
                  }
                }
              }
            }
          : undefined
      }
    />
  );
}
